/* eslint-disable */
import { useEffect, useState } from "react";

import useGRPCCLientContext from "hooks/useGRPCClientContext";
import { ValidateRequest } from "protos/document_pb";

export default (setShowLoader, setShowUserError, setError) => {
  const { clients } = useGRPCCLientContext();

  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const doValidation = () => {
    setIsLoading(true);
    setError(null);
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const req = new ValidateRequest();
    req.setToken(sessionStorage.getItem("token"));
    req.setFileid(sessionStorage.getItem("credentials"));

    clients.documentPromiseClient
      .validate(req, {})
      .then((res) => {
        setResponse(res.toObject());
        console.dir(res.toObject());
      })
      .catch((err) => {
        console.log({ err });
        const showErrCodes = [
          2,
          "PDF_TO_BIG",
          "UNSUPPORTED_FILE_TYPE",
          "NON_FLAT_PDF",
          "UNSUPPORTED_FILE_TYPE",
          "XML_NOT_WELL_FORMED",
        ];

        if (err.code !== 2) {
          if (showErrCodes.includes(err.metadata?.errorcode)) {
            setError(err);
            setShowUserError(true);
          }
        }

        setShowLoader(false);
      })
      .finally(() => setIsLoading(false));
  }, [isLoading, setIsLoading]);

  return [{ isLoading, response }, doValidation];
};
