import ClipLoader from "react-spinners/ClipLoader";

import s from "./blue-screen.module.scss";

const BlueScreen = () => {
  return (
    <div className={s.popupBackground}>
      <ClipLoader color={"#ffffff"} loading={true} size={150} />
    </div>
  );
};

export default BlueScreen;
