import { createSlice } from "@reduxjs/toolkit";
import { TokenLogoutRequest } from "protos/auth_pb";
import { AuthorizationClient } from "protos/auth_grpc_web_pb";

const getQueryString = (clientId, portalUrl) =>
  Object.entries({
    response_type: "code",
    client_id: clientId,
    redirect_uri: `${portalUrl}/idf-callback`,
    scope: [
      `openid`,
      `email`,
      `com.cleverbase.personal_info`,
      `com.cleverbase.signing_certificate`,
      `com.cleverbase.authentication_certificate`,
      `com.cleverbase.id_number`,
      `com.cleverbase.id_document_issuance_info`,
    ].join("%20"),
    lang: `en-US`,
    state: `12345678`,
    nonce: `148935887774442555558478966`,
  })
    .map((e) => e.join("="))
    .join("&");

const initialState = {
  token: null,
  user: {},
  anonymous: true,
  // loginUrl: REACT_APP_OAUTH_PORTAL_URL + getQueryString(REACT_APP_CLIENT_ID, REACT_APP_PORTAL_URL),
  loginUrl: null,
  env: null,
};

export const globalSlice = createSlice({
  name: "global-state",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user =
        Object.entries(payload || {}).length > 0 ? payload : state.user;
      state.anonymous = false;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    logout: () => {
      const { REACT_APP_GRPC_ADDRESS } = window.envVars;
      const authClient = new AuthorizationClient(REACT_APP_GRPC_ADDRESS);
      const logoutRequest = new TokenLogoutRequest();
      logoutRequest.setToken(sessionStorage.getItem("token"));
      authClient.logout(logoutRequest, {}, () => {});
      sessionStorage.clear();
      window.location.href = "/";
    },
    login: (state) => (window.location.href = state.loginUrl),
    clearSession: sessionStorage.clear,
    setVars: (state, { payload }) => {
      const {
        REACT_APP_OAUTH_PORTAL_URL,
        REACT_APP_CLIENT_ID,
        REACT_APP_PORTAL_URL,
      } = payload;
      state.env = payload;
      state.loginUrl =
        REACT_APP_OAUTH_PORTAL_URL +
        getQueryString(REACT_APP_CLIENT_ID, REACT_APP_PORTAL_URL);
    },
  },
});

export const { setUser, setToken, logout, login, clearSession, setVars } =
  globalSlice.actions;

export default globalSlice.reducer;
