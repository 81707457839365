import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

const RegisterButton = styled.a`
background: ${({ theme }) => theme.colors.salmon};
display: inline-block;
padding: 15px 25px;
border-radius: 6px;
color: #fff;
`

const Register = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
align-items: flex-start;
margin: 20px;
`


const RegisterNowBlock = () => {
    const { i18n: { language } } = useTranslation();
    const translations = {
        nl: {
            title: 'Registreer nu',
            description: 'Je bent als anonieme gebruiker ingelogd. Als je gebruik wilt maken van Vidua Signing, meld je dan nu hier aan:',
            button: 'Registreer nu'
        },
        en: {
            title: 'Register now',
            description: 'You are logged in as an anonymous user. If you want to use Vidua Signing, please register now:',
            button: 'Register now'
        }
    }
    const { title, description, button } = translations[language];
    return <Register>
        <h2>{title}</h2>
        <p>{description}</p>
        <RegisterButton href="https://vidua.nl/aanmelden/">{button}</RegisterButton>
    </Register>
}

export default RegisterNowBlock;