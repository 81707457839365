import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useLanguage = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!!localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language"));
    } else {
      localStorage.setItem("language", i18n.language);
    }
  }, [i18n]);
};

export default useLanguage;
