import { useTranslation, Trans } from "react-i18next";
import arrow from "./assets/arrow.svg";
import s from "./user-error.module.scss";

const UserError = ({ errorCode, signFailed, pdfTooBig, rerender }) => {
  const { t } = useTranslation();
  let defaultErr = localStorage.getItem("error") === "DEFAULT";

  if (!errorCode) errorCode = { metadata: {} };

  const getMain = (errorCode) => {
    if (errorCode?.metadata?.errorcode === "NON_FLAT_PDF") return;

    const main = {
      PDF_TO_BIG: t("user-error.pdfTooBig"),
      XML_TO_BIG: t("user-error.xmlTooBig"),
      DOCUMENT_IS_NOT_PROPER_PDF: t("user-error.notPdf"),
      UNSUPPORTED_FILE_TYPE: t("user-error.unknownFormat"),
      XML_NOT_WELL_FORMED: (
        <Trans i18nKey="user-error.xmlNotWellFormed">
          Het XML document dat je hebt geselecteerd heeft een ongeldige
          structuur en kan niet ondertekend worden. Gebruik deze online{" "}
          <a
            href="https://www.w3schools.com/xml/xml_validator.asp"
            target="_blank"
            rel="noreferrer"
          >
            validator
          </a>{" "}
          om vast te stellen wat er mis is met het document. Hulp nodig bij deze
          foutmelding? Neem dan contact op met onze klantenservice.
        </Trans>
      ),
      DEFAULT: t("user-error.default"),
    };

    return main[errorCode] || main["DEFAULT"];
  };

  if (defaultErr) {
    return (
      <div className={signFailed ? s.errorSignContainer : s.errorContainer}>
        <img className={s.blockArrow} src={arrow} alt="arrow" />
        <div className={s.header}>{t("user-error.header")}</div>
        <div>{rerender}</div>
        <div className={s.main}>{t("user-error.default")}</div>
      </div>
    );
  }

  return (
    <div className={signFailed ? s.errorSignContainer : s.errorContainer}>
      <img className={s.blockArrow} src={arrow} alt="arrow" />
      <div className={s.header}>{t("user-error.header")}</div>
      <div>{rerender}</div>
      {/*  sign error */}
      {signFailed && (
        <div className={s.main}>
          Het document bevat minstens één ongeldige handtekening. Wij raden aan
          dit document niet te ondertekenen. Wil je het toch ondertekenen? Maak
          het PDF dan eerst plat (lees
          <a
            href="https://www.adobe.com/ae_en/acrobat/how-to/print-to-pdf.html"
            target="_blank"
            rel="noreferrer"
          >
            hier
          </a>
          hoe) en controleer de inhoud daarna goed. Hulp nodig bij deze
          foutmelding? Neem dan contact op met onze klantenservice.
        </div>
      )}

      {/* custom errors */}
      {!signFailed &&
        errorCode?.metadata?.errorcode === "NON_FLAT_PDF" &&
        !pdfTooBig &&
        !defaultErr && (
          <>
            <div className={s.main}>
              <Trans i18nKey="user-error.notFlatPdf">
                Het PDF document dat je hebt geselecteerd bevat formulieren en
                wordt momenteel niet ondersteund. Je kan dit makkelijk oplossen
                door je PDF plat te maken, lees hier
                <a
                  href="https://www.adobe.com/ae_en/acrobat/how-to/print-to-pdf.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  How to print to PDF file in 5 easy steps | Adobe Acrobat DC
                </a>
                hoe. Hulp nodig bij deze foutmelding? Neem dan contact op met
                onze klantenservice.
              </Trans>
            </div>
          </>
        )}

      {!signFailed &&
        errorCode?.metadata?.errorcode !== "NON_FLAT_PDF" &&
        !pdfTooBig &&
        !defaultErr && (
          <>
            <div className={s.main}>
              {getMain(errorCode.metadata?.errorcode)}
            </div>
          </>
        )}

      {pdfTooBig && errorCode !== "XML_TO_BIG" && (
        <>
          <div className={s.main}>{t("user-error.pdfTooBig")}</div>
        </>
      )}

      {pdfTooBig && errorCode === "XML_TO_BIG" && (
        <>
          <div className={s.main}>{t("user-error.xmlTooBig")}</div>
        </>
      )}
    </div>
  );
};

export default UserError;
