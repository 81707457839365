export interface IWeekday {
  date: string;
  opensAt: string;
  closesAt: string;
  isClosed: boolean;
  description?: string;
}

export interface IClosingWindow {
  date: string;
  startsAt: string;
  endsAt: string;
}

export interface IUpcomingOpeningHours {
  isOpen: boolean;
  weekdays: IWeekday[];
  closingWindows?: IClosingWindow[];
}

export interface OpeningHoursPreviewProps {
  isOpen: boolean;
  weekdays: IWeekday[];
  closingWindows?: IClosingWindow[];
  t: ITranslate;
}

export interface IClosingWindowsProps {
  closingWindows: IClosingWindow[];
  t: ITranslate;
}

export interface IOpeningHoursProps {
  getOpeningHours: () => Promise<IUpcomingOpeningHours>;
  language?: SupportedLanguages;
}

export enum SupportedLanguages {
  en = "en",
  nl = "nl"
}

export type ITranslate = (term: string) => any;

export interface WeekdayPreviewProps {
  weekday: IWeekday;
  t: ITranslate;
}

export type TranslationType = {
  [l: string]: { [term: string]: any }
}
