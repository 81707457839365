import Navigation from "components/navigation";
import styled from "styled-components";
import logo from "./assets/logo.svg";
import { useHistory } from "react-router-dom";

const Header = () => {
  let history = useHistory();
  const handleClick = () => history.push("/");

  return (
    <HeaderBar>
      <HeaderContainer>
        <LogoButton onClick={handleClick} id="logo">
          <LogoImg src={logo} alt="Vidua logo" />
        </LogoButton>
        <Navigation />
      </HeaderContainer>
    </HeaderBar>
  );
};

export default Header;

const HeaderBar = styled.div`
  position: fixed;
  z-index: 7;
  top: 0;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.1);

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background: ${({ theme: { colors } }) => colors?.rainbowGradient || "#fff"};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 1180px;
`;

const LogoButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  padding-left: 20px;

  &:active {
    outline: none;
  }
`;

const LogoImg = styled.img`
  width: 80px;
`;
