import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: ${({ theme }) => {
      if(theme && theme.defaultFont) return theme.defaultFont;
      return 'serif';
    }};

    // tablet-portrait-up
    @media (min-width: 600px) {
      font-size: 12px;
    }

    // tablet-landscape-up
    @media (min-width: 900px) {
      font-size: 14px;
    }

    // desktop-up
    @media (min-width: 1200px) {
      font-size: 16px;
    }
    
    // big-desktop-up
    @media (min-width: 1800px) {
      font-size: 20px;
    }
  }

  h1 {
    font-family: Roboto;
    font-size: 5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.linkColor};
    
    &:hover {
      color: ${({ theme }) => theme.linkColorHover};
    }
  }

  label {
    min-width: 150px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: rgb(55,55,55);
    margin-right: 20px;
  }

  button {
    cursor: pointer;
  }
`

export default GlobalStyle;