import 'core-js/proposals/promise-with-resolvers';
import { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";

import useBackBtn from "hooks/useBackButton";
import Header from "components/header";
import Loader from "components/loader";
import TermsOfUseModal from "components/modal";
import usePDFRestore from "./hooks/usePDFRestore";
import useMobile from "hooks/useMobile";
import useLanguage from "hooks/useLanguage";

import backArrow from "./assets/back-arrow.svg";
import checkbox from "./assets/checkbox.svg";
import checkboxUncheck from "./assets/uncheck-checkbox.svg";
import info from "./assets/info.svg";
import s from "./pdf-preview-page.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `/lib/pdf.worker.js`;

const PdfPreviewPage = () => {
  const {
    REACT_APP_ESIGN_PORTAL_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_PORTAL_URL,
  } = window.envVars;
  const { t } = useTranslation();

  const pdfFile = sessionStorage.getItem("pdfFile");
  const [numPages, setNumPages] = useState(1);
  const [checkboxSelected, setCheckboxSelected] = useState(
    sessionStorage.getItem("checkboxSelected") === "true"
  );
  const [showPopup, setShowPopup] = useState(false);
  const uploadResData = sessionStorage.getItem("credentials");
  const isNeedToRestorePDF = sessionStorage.getItem("restoreFile") === "y";
  const isMobile = useMobile();
  const [showMobileInfo, setShowMobileInfo] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const showLoader = usePDFRestore();

  useBeforeunload(() => {
    sessionStorage.setItem("restoreFile", "y");
  });

  useBackBtn(() => {
    sessionStorage.setItem("restoreFile", "y");
  });

  // chek language after reload page
  useLanguage();

  return (
    <>
      {showLoader && <Loader />}
      {showPopup && (
        <TermsOfUseModal showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
      <Header />
      {!showLoader && (
        <div className={s.pageContainer}>
          <div className={s.contentWrapper}>
            <div
              className={s.pdfContainer}
              style={{ background: showMobileInfo ? "#fff" : "" }}
            >
              <div className={s.pdfNavigationContainer}>
                <Document
                  file={
                    isNeedToRestorePDF
                      ? sessionStorage.getItem("pdfUrl")
                      : pdfFile
                  }
                  className={s.navDocument}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      pageNumber={index + 1}
                      height={270}
                      onClick={() => {
                        scroller.scrollTo(`page_${index + 1}`, {
                          duration: 800,
                          delay: 0,
                          smooth: "easeInOutQuart",
                          offset: -152,
                        });
                      }}
                    />
                  ))}
                </Document>
              </div>
              {isMobile && (
                <div
                  className={s.mobileNavigationContainer}
                  style={{ background: showMobileInfo ? "#fff" : "#e5e5e5" }}
                >
                  {showMobileInfo && (
                    <div
                      id="back_button_preview"
                      className={s.backContainer}
                      onClick={() => {
                        setShowMobileInfo(false);
                        document.body.style.background = "#e5e5e5";
                      }}
                    >
                      <img src={backArrow} alt="back" />
                      <span className={s.backContainerText}>
                        {t("pdf-preview-page.backContainerText")}
                      </span>
                    </div>
                  )}
                  {!showMobileInfo && (
                    <Link
                      to="/signing"
                      className={s.backContainer}
                      onClick={() => {
                        setShowMobileInfo(false);
                        document.body.style.background = "#e5e5e5";
                      }}
                    >
                      <img src={backArrow} alt="back" />
                      <span className={s.backContainerText}>
                        {t("pdf-preview-page.backContainerText")}
                      </span>
                    </Link>
                  )}
                  {!showMobileInfo && (
                    <img
                      src={info}
                      onClick={() => {
                        setShowMobileInfo(true);
                        document.body.style.background = "#fff";
                      }}
                      alt="info"
                    />
                  )}
                </div>
              )}
              {!showMobileInfo && (
                <div className={s.pdfPreview}>
                  <Document
                    file={
                      isNeedToRestorePDF
                        ? sessionStorage.getItem("pdfUrl")
                        : pdfFile
                    }
                    className={s.navDocument}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <div id={`page_${index + 1}`}>
                        <Page
                          className={s.mobilePage}
                          id={`page_${index + 1}`}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          width={!isMobile ? 880 : 334}
                        />
                      </div>
                    ))}
                  </Document>
                </div>
              )}
              {showMobileInfo && (
                <div className={s.mobileInfoContainer}>
                  <div className={s.documentName}>
                    {sessionStorage.getItem("fileName") || "document_name.pdf"}
                  </div>
                  <a
                    className={s.question}
                    href="https://vidua.nl/aanmelden/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("pdf-preview-page.question")}
                  </a>
                </div>
              )}
            </div>

            <div className={s.actionContainer}>
              <Link
                to="/signing"
                className={s.backContainer}
                onClick={() => sessionStorage.setItem("restoreFile", "y")}
              >
                <img src={backArrow} alt="back" />
                <span className={s.backContainerText}>
                  {t("pdf-preview-page.backContainerText")}
                </span>
              </Link>
              <div className={s.documentName}>
                {sessionStorage.getItem("fileName") || "document_name.pdf"}
              </div>
              <a
                className={s.question}
                href="https://vidua.nl/aanmelden/"
                target="_blank"
                rel="noreferrer"
              >
                {t("pdf-preview-page.question")}
              </a>

              {/* checkbox */}
              <div className={s.allowCheckBoxContainer}>
                <img
                  id="tc_button_preview"
                  src={checkboxSelected ? checkbox : checkboxUncheck}
                  onClick={() => {
                    sessionStorage.setItem(
                      "checkboxSelected",
                      !checkboxSelected
                    );
                    setCheckboxSelected(!checkboxSelected);
                  }}
                  className={s.checkboxImg18}
                  alt="checkbox"
                  data-testid="checkbox"
                />

                <div>
                  <span
                    className={s.checkboxText}
                    onClick={() => {
                      sessionStorage.setItem(
                        "checkboxSelected",
                        !checkboxSelected
                      );
                      setCheckboxSelected(!checkboxSelected);
                    }}
                    data-testid="checkbox-text"
                  >
                    {t("pdf-preview-page.checkboxText")}
                  </span>
                  <span
                    className={s.linkToPdf}
                    onClick={() => {
                      setShowPopup(true);
                      document.body.style = "background: #fff";
                    }}
                  >
                    {t("pdf-preview-page.linkToPdf")}
                  </span>
                </div>
              </div>

              {/* button */}
              <div className={s.btnContainer}>
                <button
                  id="sign_button_preview"
                  className={
                    !checkboxSelected ||
                    !sessionStorage.getItem("fileName") ||
                    !uploadResData ||
                    sessionStorage.getItem("signErr") === "true"
                      ? s.btnDisable
                      : s.btn
                  }
                  onClick={() => {
                    sessionStorage.setItem("showBlueScreen", "true");
                    sessionStorage.setItem("signComplete", "false");
                    return (window.location.href = `${REACT_APP_ESIGN_PORTAL_URL}response_type=code&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_PORTAL_URL}/sign-callback&scope=service&lang=en-US&state=12345678`);
                  }}
                  disabled={
                    !checkboxSelected ||
                    !sessionStorage.getItem("fileName") ||
                    sessionStorage.getItem("signErr") === "true"
                  }
                  data-testid="download-btn"
                >
                  <div className={s.text}>
                    {t("pdf-preview-page.btnContainer")}
                  </div>
                </button>
              </div>
            </div>

            {isMobile && (
              <div className={s.mobileFooter}>
                {/* checkbox */}
                <div className={s.allowCheckBoxContainer}>
                  <img
                    src={checkboxSelected ? checkbox : checkboxUncheck}
                    onClick={() => {
                      sessionStorage.setItem(
                        "checkboxSelected",
                        !checkboxSelected
                      );
                      setCheckboxSelected(!checkboxSelected);
                    }}
                    className={s.checkboxImg18}
                    alt="checkbox"
                    data-testid="checkbox"
                  />

                  <div>
                    <span
                      className={s.checkboxText}
                      onClick={() => {
                        sessionStorage.setItem(
                          "checkboxSelected",
                          !checkboxSelected
                        );
                        setCheckboxSelected(!checkboxSelected);
                      }}
                      data-testid="checkbox-text"
                    >
                      {t("pdf-preview-page.checkboxText")}
                    </span>
                    <span
                      className={s.linkToPdf}
                      onClick={() => setShowPopup(true)}
                    >
                      {t("pdf-preview-page.linkToPdf")}
                    </span>
                  </div>
                </div>
                {/* button */}
                <div className={s.btnContainer}>
                  <button
                    className={
                      !checkboxSelected ||
                      !sessionStorage.getItem("fileName") ||
                      !uploadResData
                        ? s.btnDisable
                        : s.btn
                    }
                    onClick={() => {
                      sessionStorage.setItem("showBlueScreen", "true");
                      sessionStorage.setItem("signComplete", "false");
                      return (window.location.href = `${REACT_APP_ESIGN_PORTAL_URL}response_type=code&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_PORTAL_URL}/sign-callback&scope=service&lang=en-US&state=12345678`);
                    }}
                    disabled={
                      !checkboxSelected || !sessionStorage.getItem("fileName")
                    }
                    data-testid="download-btn"
                  >
                    <div className={s.text}>
                      {t("pdf-preview-page.btnContainer")}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PdfPreviewPage;
