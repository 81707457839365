import ClipLoader from "react-spinners/ClipLoader";
import React from 'react';

import s from "./loader.module.scss";

const Loader = () => {
  return (
    <div className={s.popupBackground}>
      <ClipLoader color={"#ffffff"} loading={true} size={150} />
    </div>
  );
};

export default Loader;
