/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthorizationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthorizationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.TokenRequest,
 *   !proto.auth.TokenResponse>}
 */
const methodDescriptor_Authorization_Login = new grpc.web.MethodDescriptor(
  '/auth.Authorization/Login',
  grpc.web.MethodType.UNARY,
  proto.auth.TokenRequest,
  proto.auth.TokenResponse,
  /**
   * @param {!proto.auth.TokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.TokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.auth.TokenRequest,
 *   !proto.auth.TokenResponse>}
 */
const methodInfo_Authorization_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.auth.TokenResponse,
  /**
   * @param {!proto.auth.TokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.TokenResponse.deserializeBinary
);


/**
 * @param {!proto.auth.TokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.auth.TokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.TokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthorizationClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.Authorization/Login',
      request,
      metadata || {},
      methodDescriptor_Authorization_Login,
      callback);
};


/**
 * @param {!proto.auth.TokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.TokenResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthorizationPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.Authorization/Login',
      request,
      metadata || {},
      methodDescriptor_Authorization_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.TokenLogoutRequest,
 *   !proto.auth.TokenLogoutResponse>}
 */
const methodDescriptor_Authorization_Logout = new grpc.web.MethodDescriptor(
  '/auth.Authorization/Logout',
  grpc.web.MethodType.UNARY,
  proto.auth.TokenLogoutRequest,
  proto.auth.TokenLogoutResponse,
  /**
   * @param {!proto.auth.TokenLogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.TokenLogoutResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.auth.TokenLogoutRequest,
 *   !proto.auth.TokenLogoutResponse>}
 */
const methodInfo_Authorization_Logout = new grpc.web.AbstractClientBase.MethodInfo(
  proto.auth.TokenLogoutResponse,
  /**
   * @param {!proto.auth.TokenLogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.TokenLogoutResponse.deserializeBinary
);


/**
 * @param {!proto.auth.TokenLogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.auth.TokenLogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.TokenLogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthorizationClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.Authorization/Logout',
      request,
      metadata || {},
      methodDescriptor_Authorization_Logout,
      callback);
};


/**
 * @param {!proto.auth.TokenLogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.TokenLogoutResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthorizationPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.Authorization/Logout',
      request,
      metadata || {},
      methodDescriptor_Authorization_Logout);
};


module.exports = proto.auth;

