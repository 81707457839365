import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import Carrot from "components/carrot";
import logout from "./assets/logout.svg";

function DropDown(props) {
  const dropdownRef = useRef(null);
  const [show, toggle] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target))
        toggle(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <DropDownStyle ref={dropdownRef}>
      <Label onClick={(e) => toggle(!show)} active={show}>
        {props.label}
        <Carrot active={show} />
      </Label>
      <Options show={show}>
        {props.options.map(
          ({ handleClick, label, keepopen = false, logoutIcon }, index) => {
            return (
              <DropDownOption
                key={index}
                onClick={() => {
                  toggle(keepopen);
                  handleClick();
                }}
              >
                {logoutIcon && (
                  <img
                    src={logout}
                    alt="logout"
                    style={{ padding: "0 8px 0 0" }}
                  />
                )}
                {label}
              </DropDownOption>
            );
          }
        )}
      </Options>
    </DropDownStyle>
  );
}

export default DropDown;

const DropDownStyle = styled.div`
  position: relative;
  height: 100%;
  max-width: 320px;
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  gap: 7px;
  background: ${({ active, theme }) =>
    active ? theme.colors.navigationHoverBackground : "transparent"};

  &:hover {
    background: ${({ theme }) => theme.colors.navigationHoverBackground};
  }

  @media (max-width: 375px) {
    background: #fff;

    &:hover {
      background: #fff;
    }
  }
`;

const Options = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 10px;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  min-width: 240px;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  &:after,
  &:before {
    display: block;
    position: absolute;
    content: "";
    pointer-events: none;
  }

  &:before {
    width: 0px;
    height: 0px;
    top: -20px;
    right: 17px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    border-width: 10px;
  }

  &:after {
    content: "";
    display: block;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 20px;
    top: 0;
    opacity: 0.5;
  }

  > * {
    background: #fff;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const DropDownOption = styled.a`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.navigationHoverBackground};
  }
`;
