import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Switch from "components/switch";

import s from "./modal-settings.module.scss";

const SettingsModal = ({ setShowSettingsPopup }) => {
  const node = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add(s.hideBodyScroll);

    return () => {
      document.body.classList.remove(s.hideBodyScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  const handleClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      setShowSettingsPopup(false);
    }
  };

  return (
    <div className={s.popupBackground}>
      <div className={s.popupContainer} ref={node}>
        <div className={s.header} data-testid="Popup1Header">
          {t("modal-settings.header")}
        </div>
        <div className={s.textContainer}>
          <div className={s.row}>
            <div>{t("modal-settings.preference1")}</div>
            <Switch />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
