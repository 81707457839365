import styled from 'styled-components';

export interface ContainerProps {
  [key: string]: any;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  align?: 'stretch' | 'center' | 'flex-end' | 'flex-start';
  gap?: any
}

const ContainerDiv = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  gap: ${props => {
    return typeof props.gap === 'number' 
      ? `${props.gap}px`
      : props.gap
  }};
  align-items: ${props => props.align};
  justify-content: ${props => props.align};

  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  
  // phone only
  @media (max-width: 599px) {
    max-width: 100%;
  }

  // tablet-portrait-up
  @media (min-width: 600px) {
    max-width: 100%;
  }

  // tablet-landscape-up
  @media (min-width: 900px) {
    max-width: 760px;
  }

  // desktop-up
  @media (min-width: 1200px) {
    max-width: 1080px;
  }
  
  // big-desktop-up
  @media (min-width: 1800px) {
    max-width: 1200px;
  }
`

export const Container = ({
  ...props
}: ContainerProps) => <ContainerDiv {...props} />

Container.defaultProps = {
  direction: "column",
  align: "center",
  gap: 10
};

export default Container;