import Header from "components/header";
import styled from "styled-components";
import SigningBlock from "./SigningBlock";
import MyData from "./MyData";
import Footer from "./Footer";
import FAQ from "./FAQ";

const Dashboard = () => {
  return (
    <Container>
      <Header />
      <Main>
        <SigningBlock />
        <MyData />
        <FAQ />
      </Main>
      <Footer />
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  padding-top: 68px;
`;

const Main = styled.main`
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
`;
