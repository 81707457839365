import { DefaultTheme } from "styled-components";

declare module "styled-components" {
	export interface DefaultTheme {
		importUrls: Array<string>;
		fontFamily: string;
		primaryColor: string;
		background: string;

		defaultFont: string;
		headerFont: string;
		labelFont: string;
		quoteFont: string;
		buttonFont: string;

		linkColor: string;
		linkColorHover: string;
	}
}

/* 
interface Theme {
	primaryColor: string;
	background: string;
  defaultFont: string;
	linkColor: string;
	linkColorHover: string;
} 
*/

const defaultTheme: DefaultTheme = {
	fontFamily: 'sans-serif',
	primaryColor: `blue`,
	background: `#f2f2f2`,

	/* Fonts */
	defaultFont: `sans-serif`,
	headerFont: `cursive`,
	labelFont: `inherit`,
	quoteFont: `inherit`,
	buttonFont: `inherit`,
	
	linkColor: `blue`,
	linkColorHover: `blue`,

	colorError: '#EB3232',
	colorSuccess: '#6FAC9D',
}



export default defaultTheme;