import { createContext, useEffect, useState } from "react";
import axios from "axios";
import Loader from "components/loader";
import React from 'react';

export const ConfigContext = createContext(null);

export const ConfigProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    axios
      .get("/config/config.json")
      .then((res) => setConfig(res.data))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Loader />;
  if (error) {
    return <div>Error loading configuration</div>;
  }
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
