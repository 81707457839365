/* eslint-disable */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";

import { SignRequest } from "protos/document_pb";
import useGRPCCLientContext from "hooks/useGRPCClientContext";

import s from "../home-page.module.scss";

export default (
  setShowBlueScreen,
  displayError,
  setShowError,
  setRedirectToHome,
  setDocumentName,
  downloadPDF,
  setShowLoader,
  setShowSystemError
) => {
  const location = useLocation();
  const parsedSearch = parse(location.search);
  const { clients } = useGRPCCLientContext();
  const isNeedSignPage = !!localStorage.getItem("stamp")
    ? localStorage.getItem("stamp") === "true"
      ? true
      : false
    : true;

  useEffect(() => {
    if (
      location.pathname === "/sign-callback" &&
      typeof parsedSearch.code === "string" &&
      sessionStorage.getItem("signComplete") === "false"
    ) {
      setShowBlueScreen(true);
      document.body.classList.add(s.hideBodyScroll);
      const signReq = new SignRequest();
      signReq.setToken(sessionStorage.getItem("token"));
      signReq.setCode(parsedSearch.code);
      signReq.setFileid(sessionStorage.getItem("credentials"));
      signReq.setStampenabled(isNeedSignPage);
      signReq.setLanguage(localStorage.getItem("language"));
      sessionStorage.setItem("restoreFile", "n");

      clients.documentPromiseClient
        .sign(signReq, {})
        .then((res) => {
          const signRes = res.toObject();
          setDocumentName(signRes.filename);
          sessionStorage.setItem("documentName", signRes.filename);

          if (signRes && signRes.redirect === true) {
            return (window.location.href = signRes.url);
          } else {
            document.body.classList.remove(s.hideBodyScroll);
            sessionStorage.setItem("signComplete", true);
            downloadPDF();
          }
        })
        .catch((err) => {
          console.log({ err });
          setShowLoader(false);
          displayError(err?.message, err?.code);
          setShowError(true);
          setShowSystemError(true);
          sessionStorage.removeItem("fileName");
          sessionStorage.setItem("err", err?.code);

          //clear file on error
          sessionStorage.removeItem("restoreFile");
          sessionStorage.removeItem("pdfUrl");
          sessionStorage.removeItem("fileName");
          sessionStorage.removeItem("pdfFile");

          return setRedirectToHome(true);
        })
        .finally(() => {
          setShowBlueScreen(false);
          sessionStorage.setItem("showBlueScreen", "false");
        });
    }
  }, []);
};
