import styled from "styled-components";
import Icon from "assets/download.svg";
import { downloadStringToFile } from "helpers";
import { useTranslation } from "react-i18next";

const Grid = styled.div`
  display: grid;
  background: #f2f2f2;
  padding: 10px 25px 10px 15px;
  border-radius: 4px;
  position: relative;
  width: 100%;
`;

const DateValid = styled.span`
  font-size: 12px;
  color: #999;
`;

const Download = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 40px;
  height: 40px;
  transform: translate(0%, -50%);
  border: none;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Certificate = ({ title, date, file }) => {
  const { i18n } = useTranslation();

  const language = i18n.language || "nl";

  return (
    <Grid>
      <span style={{ wordWrap: "break-word" }}>{title}</span>
      {date && (
        <DateValid>
          {language === "en" ? "Valid until: " : "Geldig tot: "}{" "}
          {date.toUTCString()}
        </DateValid>
      )}
      <Download
        onClick={() =>
          downloadStringToFile({ filename: file.name, content: file.content })
        }
      >
        <img src={Icon} alt="" />
      </Download>
    </Grid>
  );
};

export default Certificate;
