import { useCallback, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";

import {
  PresignedURLDownloadRequest,
  PresignedURLRequest,
} from "protos/document_pb";
import useRestorePDF from "features/home-page/hooks/useRestorePDF";
import useTimeout from "features/home-page/hooks/useTimeout";
import useSign from "features/home-page/hooks/useSign";
import useValidation from "features/home-page/hooks/useValidation";
import useGRPCCLientContext from "hooks/useGRPCClientContext";
import useLanguage from "hooks/useLanguage";
import Header from "components/header";
import Loader from "components/loader";
import BlueScreen from "components/blue-screen";
import DragDrop from "components/drag-drop";
import TermsOfUseModal from "components/modal";
import HowToValidateModal from "components/modal-validate";
import PdfPreview from "components/pdf-preview";
import UserError from "components/user-error";
import SystemError from "components/system-error";
import { translateToDutch, MAX_FILE_SIZE, MAX_XML_SIZE } from "helpers";

import s from "./home-page.module.scss";

const HomePage = () => {
  const location = useLocation("");
  const { clients } = useGRPCCLientContext();

  const { REACT_APP_VERSION } = window.envVars;

  const { t } = useTranslation();
  const [checkboxSelected, setCheckboxSelected] = useState(
    sessionStorage.getItem("checkboxSelected") === "true"
  );
  const [documentName, setDocumentName] = useState("");
  const [uploadResData, setUploadResData] = useState();
  const [signResData, setSignResData] = useState();
  const [blockBtn, setBlockBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderInHeader, setShowLoaderInHeader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUserError, setShowUserError] = useState(false);
  const [showUserErrorWithPDF, setShowUserErrorWithPDF] = useState(false);
  const [showSystemError, setShowSystemError] = useState(
    sessionStorage.getItem("err")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [showBlueScreen, setShowBlueScreen] = useState(
    sessionStorage.getItem("showBlueScreen") === "true"
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [pdfFileContent, setPdfFileContent] = useState(null);
  const needToResotePdf = sessionStorage.getItem("restoreFile") === "y";
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [error, setError] = useState(null);
  let [{ isLoading, response }, doValidation] = useValidation(
    setShowLoader,
    setShowUserError,
    setError
  );
  const [pdfTooBig, setPdfTooBig] = useState(false);
  const [rerender, setRerender] = useState(false);

  const downloadPDFLink = (url, documenttype) => {
    const uploadedFile = sessionStorage.getItem("fileName");

    const getExtension = (documenttype) => {
      const fileExtensions = {
        pdf: ".pdf",
        xml: ".xml",
        binary: "",
        default: "",
      };
      return (
        fileExtensions[documenttype.toLowerCase()] || fileExtensions.default
      );
    };

    let downloadName = uploadedFile
      ? `${uploadedFile.substring(
          0,
          uploadedFile.length - 4
        )}-signed${getExtension(documenttype)}`
      : `signed${getExtension(documenttype)}`;

    if (documenttype === "BINARY" || documenttype === "XML") {
      downloadName = `${uploadedFile}-detached-signature`;
    }

    setShowLoader(true);

    axios
      .get(url, { responseType: "blob" })
      .then((res) => {
        setPdfFileContent(res.data);
        FileSaver.saveAs(res.data, downloadName);
      })
      .catch((err) => handleErrorResponse(err))
      .finally(() => setShowLoader(false));
  };

  const handleDownloadClick = () => {
    if (!blockBtn && signResData) {
      downloadPDF();
      setBlockBtn(true);
    }
  };

  // 2 send pdf
  const onDrop = useCallback((acceptedFiles) => {
    //clear prev pdf -->
    sessionStorage.removeItem("restoreFile");
    sessionStorage.removeItem("pdfUrl");
    sessionStorage.removeItem("fileName");
    setPdfFile(null);
    setUploadResData(null);
    setShowUserError(false);
    setError(null);
    setShowUserErrorWithPDF(false);
    setErrorCode(null);
    sessionStorage.removeItem("signErr");
    setPdfTooBig(false);
    setRerender(!rerender);
    // <--

    if (!acceptedFiles || !acceptedFiles[0]) {
      setShowUserError(true);
      setError("DEFAULT");
      localStorage.setItem("error", "DEFAULT");
      return;
    }

    // setShowLoader(true);
    setShowLoaderInHeader(true);
    setSignResData(null);
    const file = acceptedFiles[0];

    if (file.size > MAX_FILE_SIZE) {
      setError("PDF_TO_BIG");
      localStorage.removeItem("error");
      // setShowLoader(false);
      setShowLoaderInHeader(false);
      setShowUserError(true);
      setPdfTooBig(true);
      return;
    }

    const xblrCondition =
      file.name.length > 6 &&
      file.name
        .substring(file.name.length - 4, file.name.length)
        .toLowerCase() === "xbrl";

    if (
      (file.type === "text/xml" || xblrCondition) &&
      file.size > MAX_XML_SIZE
    ) {
      setError("XML_TO_BIG");
      localStorage.removeItem("error");
      // setShowLoader(false);
      setShowLoaderInHeader(false);
      setShowUserError(true);
      setPdfTooBig(true);
      return;
    }

    localStorage.removeItem("error");
    setPdfFile(URL.createObjectURL(file));
    sessionStorage.setItem("fileName", file.name);
    sessionStorage.setItem("pdfFile", URL.createObjectURL(file));

    getPresignedURL(file, (err, res) => {
      if (err) return handleErrorResponse(err);
      const { url, fileid } = res.toObject();
      uploadFile(url, file)
        .then(() => {
          // setShowLoader(false);
          setShowLoaderInHeader(false);
          setUploadResData(fileid);
          sessionStorage.setItem("credentials", fileid);

          // validate pdf file
          doValidation();
        })
        .catch((err) => handleErrorResponse(err));
    });
  });

  const displayError = (msg, code) => {
    setErrorMessage(translateToDutch(msg));
    setErrorCode(code);
  };

  const handleErrorResponse = (err) => {
    console.log({ err });
    setShowLoader(false);
    displayError(err.message, err.code);
    setShowError(true);
    sessionStorage.removeItem("fileName");
  };

  /**
   * Calls gRPC method GetPresignedURL to get presigned URL for uploading file to S3
   *
   * @param {File} file - file to be uploaded
   * @param {Function} callback - function to be called on error or success
   */
  const getPresignedURL = (file, callback) => {
    const req = new PresignedURLRequest();
    req.setToken(sessionStorage.getItem("token") || "");
    req.setFilename(file.name);
    clients.documentClient.getPresignedURL(req, {}, callback);
  };

  /**
   * Uploads file to S3 with presigned URL
   *
   * @param {String} url - PreSigned URL for uploading
   * @param {File} file - file to be uploded
   * @return {Promise<AxiosResponse<any>>} response
   */
  const uploadFile = (url, file) => {
    return axios.put(url, file, {
      headers: { "Content-Type": file.type },
    });
  };

  // call download API to GET download URL
  const downloadPDF = () => {
    const downloadReq = new PresignedURLDownloadRequest();
    downloadReq.setFileid(sessionStorage.getItem("credentials"));
    downloadReq.setToken(sessionStorage.getItem("token"));

    clients.documentPromiseClient
      .download(downloadReq, {})
      .then((res) => {
        sessionStorage.setItem("downloadSuccess", true);
        const { url, documenttype } = res.toObject();
        setSignResData(url);
        downloadPDFLink(url, documenttype);
        setShowBlueScreen(false);
      })
      .catch((err) => {
        document.body.classList.remove(s.hideBodyScroll);
        return handleErrorResponse(err);
      });
  };

  //blocking download btn for 2 sec
  useTimeout(blockBtn, setBlockBtn);

  // 3 get url and redirection
  useSign(
    setShowBlueScreen,
    displayError,
    setShowError,
    setRedirectToHome,
    setDocumentName,
    downloadPDF,
    setShowLoader,
    setShowSystemError
  );

  // close blue screen on refresh
  useBeforeunload(() => {
    setShowBlueScreen(false);
    sessionStorage.setItem("showBlueScreen", "false");
    sessionStorage.removeItem("documentName");
    if (
      !!sessionStorage.getItem("pdfUrl") ||
      !!sessionStorage.getItem("pdfFile")
    )
      sessionStorage.setItem("restoreFile", "y");

    if (
      location.pathname === "/sign-callback" &&
      sessionStorage.getItem("signComplete") !== "false" &&
      !!sessionStorage.getItem("signComplete")
    ) {
      sessionStorage.removeItem("restoreFile");
      sessionStorage.removeItem("pdfUrl");
      sessionStorage.removeItem("fileName");
      sessionStorage.removeItem("signComplete");
    }
  });

  //restore pdf file from url
  useRestorePDF(setShowLoader, displayError, setShowError);

  // download pdf after refresh
  useEffect(() => {
    if (
      location.pathname === "/sign-callback" &&
      !signResData &&
      signResData?.redirect === false &&
      sessionStorage.getItem("downloadSuccess") === "true"
    ) {
      downloadPDF();
    }
  }, []);

  // chek language after reload page
  useLanguage();

  //validate pdf after click on back btn
  useEffect(() => {
    if (
      !!sessionStorage.getItem("credentials") &&
      sessionStorage.getItem("signComplete") !== "false"
    )
      doValidation();
  }, []);

  if (redirectToHome) return <Redirect to="/signing" />;

  return (
    <>
      {showPopup && (
        <TermsOfUseModal showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
      {showPopup2 && <HowToValidateModal setShowPopup={setShowPopup2} />}
      {showBlueScreen && !signResData && <BlueScreen />}
      <Header />
      {!showError && showLoader && <Loader />}
      {(showSystemError?.length > 0 || showError) && (
        <SystemError
          setShowError={setShowError}
          errorMessage={errorMessage}
          errorCode={errorCode}
          setShowSystemError={setShowSystemError}
          showSystemError={showSystemError}
        />
      )}
      {!showBlueScreen && (
        <div className={s.pageContainer}>
          <div className={s.contentWrapper}>
            <div className={s.header}>
              <div className={s.headerText}>{t("home-page.header")}</div>
            </div>
            <div className={s.mainContentContainer}>
              <div className={s.mainContent}>
                <div className={s.pdfContent}>
                  {showUserErrorWithPDF && (
                    <>
                      <UserError
                        errorCode={error}
                        signFailed={true}
                        pdfTooBig={pdfTooBig}
                      />
                    </>
                  )}
                  {showUserError && error && (
                    <>
                      <UserError
                        errorCode={error}
                        signFailed={false}
                        pdfTooBig={pdfTooBig}
                        rerender={rerender}
                      />
                    </>
                  )}
                  {!signResData &&
                    !showLoader &&
                    (needToResotePdf || pdfFile) &&
                    !(showUserError || error) && (
                      <div className={s.pdfBlockPosition}>
                        <PdfPreview
                          pdfFile={
                            needToResotePdf
                              ? sessionStorage.getItem("pdfUrl")
                              : pdfFile
                          }
                          setPdfFile={setPdfFile}
                          signed={false}
                          setUploadResData={setUploadResData}
                          view={"default"}
                          isPanelOpen={isPanelOpen}
                          setIsPanelOpen={setIsPanelOpen}
                          isLoading={isLoading}
                          response={response}
                          error={error}
                          setShowUserErrorWithPDF={setShowUserErrorWithPDF}
                          setShowUserError={setShowUserError}
                        />
                      </div>
                    )}
                  {signResData && pdfFileContent && (
                    <div className={s.pdfBlockPosition}>
                      <PdfPreview
                        pdfFile={pdfFileContent}
                        setPdfFile={setPdfFile}
                        signed={true}
                        setUploadResData={setUploadResData}
                        view={"sign"}
                        isPanelOpen={isPanelOpen}
                        setIsPanelOpen={setIsPanelOpen}
                      />
                    </div>
                  )}
                  <DragDrop
                    location={location}
                    documentName={documentName}
                    onDrop={onDrop}
                    checkboxSelected={checkboxSelected}
                    setCheckboxSelected={setCheckboxSelected}
                    signResData={signResData}
                    setSignResData={setSignResData}
                    setShowBlueScreen={setShowBlueScreen}
                    blockBtn={blockBtn}
                    handleDownloadClick={handleDownloadClick}
                    showLoader={showLoader}
                    showLoaderInHeader={showLoaderInHeader}
                    uploadResData={uploadResData}
                    setShowPopup={setShowPopup}
                    setShowPopup2={setShowPopup2}
                    pdfFile={pdfFile}
                    isLoading={isLoading}
                    showUserError={showUserError}
                  />
                </div>
              </div>
            </div>

            <div className={s.footer}>
              <div className={s.footerText}>{REACT_APP_VERSION}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
