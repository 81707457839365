import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<{
  padding?: string
}>`
  padding: ${({ padding = '10px 15px' }) => padding};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.primaryColor };
`
const StyledLabel = styled.label<{
  padding?: string;
  position?: string;
}>`
  font-weight: bold;
  margin-right: 10px;
  color: #333;
  display: ${props => {
    if(props.position) return props.position;
    return 'block';
  }};
`

export interface InputProps {
  /**
   * How large should the button be?
   */
  type?: 'text' | 'number' | 'file' | 'submit';
  label?: string;
  labelPosition?: 'inline' | 'block';
  onClick?: () => void;
}

/**
 * https://fettblog.eu/typescript-react-why-i-dont-use-react-fc/
 * export const Input: React.FC<InputProps> = ({
 */
export const Input = ({
  type = "text",
  label,
  labelPosition,
  ...props
}: InputProps) => {
  
  function changeHandler(event: any): void {
    // do something exciting here
  }

  return (<>
      { label && (<StyledLabel position={labelPosition}>{label}</StyledLabel>)}
      <StyledInput type={type} {...props} onChange={changeHandler} />
    </>
  );
};

export default Input;