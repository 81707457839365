import chroma from 'chroma-js';
import defaultTheme from './../defaultTheme';
import { mergeDeep } from './../../utils';
import colors from './_colors'
import ViduaLogo from './../../Logo/vidua-logo-full-white-large.png';

const primaryColor = '#115D99';

const theme = mergeDeep(
  defaultTheme,
  {
    primaryColor: colors.blue,
    borderRadius: '50px',
    background: `#F6F6F6`,
    fontFamily: '"Nunito Sans", sans-serif',
    defaultFont: `"Nunito Sans", sans-serif`,
    headerFont: `"Nunito Sans", sans-serif`,
    linkColor: colors.blue,
    linkColorHover: `${chroma(colors.blue).brighten()}`,
    logo: ViduaLogo,
    inloggenBackgroundColor: primaryColor,
    defaultButtonColor: colors.blue,
    defaultButtonColorHover: colors.darkBlue,
    colors
  }
)

export default theme;
