/* eslint-disable */
import { useEffect } from "react";

import { PreviewRequest } from "protos/document_pb";
import useGRPCCLientContext from "hooks/useGRPCClientContext";

export default (f, displayError, setShowError) => {
  const { clients } = useGRPCCLientContext();

  useEffect(() => {
    if (sessionStorage.getItem("restoreFile") === "y") {
      f(true);
      const req = new PreviewRequest();
      req.setToken(sessionStorage.getItem("token"));
      req.setFileid(sessionStorage.getItem("credentials"));
      clients.documentPromiseClient
        .preview(req, {})
        .then((res) => {
          const { url } = res.toObject();
          sessionStorage.setItem("pdfUrl", url);
          f(false);
        })
        .catch((err) => {
          console.log({ err });
          displayError(err?.message, err?.code);
          setShowError(true);
        });
    }
  }, []);
};
