import { createContext } from "react";
import React from 'react';

import {
  AuthorizationClient,
  AuthorizationPromiseClient,
} from "protos/auth_grpc_web_pb";
import {
  DocumentClient,
  DocumentPromiseClient,
} from "protos/document_grpc_web_pb";

export const GRPCContext = createContext({});

export const GRPCClientsProvider = ({ children, data }) => {

  const { REACT_APP_GRPC_ADDRESS } = data;

  const clients = {
    authClient: new AuthorizationClient(REACT_APP_GRPC_ADDRESS),
    authPromiseClient: new AuthorizationPromiseClient(REACT_APP_GRPC_ADDRESS),
    documentClient: new DocumentClient(REACT_APP_GRPC_ADDRESS),
    documentPromiseClient: new DocumentPromiseClient(REACT_APP_GRPC_ADDRESS),
  };

  return (
    <GRPCContext.Provider value={{ clients }}>{children}</GRPCContext.Provider>
  );
};
