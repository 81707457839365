import Question from "components/faq-question";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const FAQ_QUESTIONS_COUNT = 3;

const FAQ = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq' });
  const questions = Array.from(Array(FAQ_QUESTIONS_COUNT), (v, i) => ({
    question: t(`question${i+1}`),
    answer: t(`answer${i+1}`)
  }));

  return (
    <Container id="FAQ">
      <Header>
        {t("F.A.Q.")}
      </Header>
      {questions.map((q, i) => (
          <Question id={`question-${i}`} key={i} {...q} />
      ))}
      <Paragraph>
        {t("Contact us")}
        <br />
        <br />
        <Link className="phone" href="tel:+31708209680">
          (070) 820 96 80
        </Link>
        <br />
        <Link className="email" href="mailto:klantenservice@vidua.nl">
          klantenservice@vidua.nl
        </Link>
      </Paragraph>
    </Container>
  );
};

export default FAQ;

const Header = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.linkColor};
`;

const Paragraph = styled.p`
  margin-top: 20px;
  line-height: 22px;
`;

const Container = styled.div`
  max-width: 870px;
  width: 100%;
  margin: 80px auto;
  padding-left: 20px;
  padding-right: 20px;

  @media ${({ theme: { device } }) => device.mobile} {
    margin: 30px auto 45px auto;
    padding-left: 20px;
  }
`;
