/**
 * @fileoverview gRPC-Web generated client stub for document
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.document = require('./document_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.document.DocumentClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.document.DocumentPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document.PresignedURLDownloadRequest,
 *   !proto.document.PresignedURLDownloadResponse>}
 */
const methodDescriptor_Document_Download = new grpc.web.MethodDescriptor(
  '/document.Document/Download',
  grpc.web.MethodType.UNARY,
  proto.document.PresignedURLDownloadRequest,
  proto.document.PresignedURLDownloadResponse,
  /**
   * @param {!proto.document.PresignedURLDownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PresignedURLDownloadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document.PresignedURLDownloadRequest,
 *   !proto.document.PresignedURLDownloadResponse>}
 */
const methodInfo_Document_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto.document.PresignedURLDownloadResponse,
  /**
   * @param {!proto.document.PresignedURLDownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PresignedURLDownloadResponse.deserializeBinary
);


/**
 * @param {!proto.document.PresignedURLDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document.PresignedURLDownloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document.PresignedURLDownloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.document.DocumentClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/document.Document/Download',
      request,
      metadata || {},
      methodDescriptor_Document_Download,
      callback);
};


/**
 * @param {!proto.document.PresignedURLDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document.PresignedURLDownloadResponse>}
 *     Promise that resolves to the response
 */
proto.document.DocumentPromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/document.Document/Download',
      request,
      metadata || {},
      methodDescriptor_Document_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document.PresignedURLRequest,
 *   !proto.document.PresignedURLResponse>}
 */
const methodDescriptor_Document_GetPresignedURL = new grpc.web.MethodDescriptor(
  '/document.Document/GetPresignedURL',
  grpc.web.MethodType.UNARY,
  proto.document.PresignedURLRequest,
  proto.document.PresignedURLResponse,
  /**
   * @param {!proto.document.PresignedURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PresignedURLResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document.PresignedURLRequest,
 *   !proto.document.PresignedURLResponse>}
 */
const methodInfo_Document_GetPresignedURL = new grpc.web.AbstractClientBase.MethodInfo(
  proto.document.PresignedURLResponse,
  /**
   * @param {!proto.document.PresignedURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PresignedURLResponse.deserializeBinary
);


/**
 * @param {!proto.document.PresignedURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document.PresignedURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document.PresignedURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.document.DocumentClient.prototype.getPresignedURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/document.Document/GetPresignedURL',
      request,
      metadata || {},
      methodDescriptor_Document_GetPresignedURL,
      callback);
};


/**
 * @param {!proto.document.PresignedURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document.PresignedURLResponse>}
 *     Promise that resolves to the response
 */
proto.document.DocumentPromiseClient.prototype.getPresignedURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/document.Document/GetPresignedURL',
      request,
      metadata || {},
      methodDescriptor_Document_GetPresignedURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document.SignRequest,
 *   !proto.document.SignResponse>}
 */
const methodDescriptor_Document_Sign = new grpc.web.MethodDescriptor(
  '/document.Document/Sign',
  grpc.web.MethodType.UNARY,
  proto.document.SignRequest,
  proto.document.SignResponse,
  /**
   * @param {!proto.document.SignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.SignResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document.SignRequest,
 *   !proto.document.SignResponse>}
 */
const methodInfo_Document_Sign = new grpc.web.AbstractClientBase.MethodInfo(
  proto.document.SignResponse,
  /**
   * @param {!proto.document.SignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.SignResponse.deserializeBinary
);


/**
 * @param {!proto.document.SignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document.SignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document.SignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.document.DocumentClient.prototype.sign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/document.Document/Sign',
      request,
      metadata || {},
      methodDescriptor_Document_Sign,
      callback);
};


/**
 * @param {!proto.document.SignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document.SignResponse>}
 *     Promise that resolves to the response
 */
proto.document.DocumentPromiseClient.prototype.sign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/document.Document/Sign',
      request,
      metadata || {},
      methodDescriptor_Document_Sign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document.PreviewRequest,
 *   !proto.document.PreviewResponse>}
 */
const methodDescriptor_Document_Preview = new grpc.web.MethodDescriptor(
  '/document.Document/Preview',
  grpc.web.MethodType.UNARY,
  proto.document.PreviewRequest,
  proto.document.PreviewResponse,
  /**
   * @param {!proto.document.PreviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PreviewResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document.PreviewRequest,
 *   !proto.document.PreviewResponse>}
 */
const methodInfo_Document_Preview = new grpc.web.AbstractClientBase.MethodInfo(
  proto.document.PreviewResponse,
  /**
   * @param {!proto.document.PreviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.PreviewResponse.deserializeBinary
);


/**
 * @param {!proto.document.PreviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document.PreviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document.PreviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.document.DocumentClient.prototype.preview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/document.Document/Preview',
      request,
      metadata || {},
      methodDescriptor_Document_Preview,
      callback);
};


/**
 * @param {!proto.document.PreviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document.PreviewResponse>}
 *     Promise that resolves to the response
 */
proto.document.DocumentPromiseClient.prototype.preview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/document.Document/Preview',
      request,
      metadata || {},
      methodDescriptor_Document_Preview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document.ValidateRequest,
 *   !proto.document.ValidateResponse>}
 */
const methodDescriptor_Document_Validate = new grpc.web.MethodDescriptor(
  '/document.Document/Validate',
  grpc.web.MethodType.UNARY,
  proto.document.ValidateRequest,
  proto.document.ValidateResponse,
  /**
   * @param {!proto.document.ValidateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.ValidateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document.ValidateRequest,
 *   !proto.document.ValidateResponse>}
 */
const methodInfo_Document_Validate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.document.ValidateResponse,
  /**
   * @param {!proto.document.ValidateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.document.ValidateResponse.deserializeBinary
);


/**
 * @param {!proto.document.ValidateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document.ValidateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document.ValidateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.document.DocumentClient.prototype.validate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/document.Document/Validate',
      request,
      metadata || {},
      methodDescriptor_Document_Validate,
      callback);
};


/**
 * @param {!proto.document.ValidateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document.ValidateResponse>}
 *     Promise that resolves to the response
 */
proto.document.DocumentPromiseClient.prototype.validate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/document.Document/Validate',
      request,
      metadata || {},
      methodDescriptor_Document_Validate);
};


module.exports = proto.document;

