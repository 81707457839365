import styled from "styled-components";

const Carrot = styled.span`
display: inline-block;
width: 24px;
height: 24px;
position: relative;

&:after,
&:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 2px;
    background: ${({ theme }) => theme.colors.textColor};
    border-radius: 2px;
    transition: transform 300ms;
}

&:before {
    transform: ${({ active }) => active ? 'translate(-7px, -3px) rotate(-35deg)' : 'translate(-7px,1px) rotate(35deg)'};
    transform-origin: 7px 50%;
}

&:after {
    transform: ${({ active }) => active ? 'translate(-1px, -3px) rotate(35deg)' : 'translate(-1px, 1px) rotate(-35deg)'};
    transform-origin: 1px 50%;
}
`

export default Carrot;