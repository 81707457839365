import React, { Children } from 'react';
import styled from 'styled-components';
import ViduaLogo from '../Logo/vidua-logo-full-color.svg';

const BackgroundContainer = styled.div`
  background: ${({ theme }) => theme.background};
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.defaultFont};
  padding-bottom: 50px;
  gap: 50px;
  box-sizing: border-box;

  @media screen and (min-width: 880px) {
    min-height: 600px;
    height: 100vh;
  }
`

const Content = styled.main`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 50px;
width: 100%;
z-index: 1;
`

const Logo = styled.img`
margin-top: 50px;

@media screen and (min-width: 880px) {
  margin-top: 0;
  position: absolute;
  top: 50px;
  left: 50px;
}
`

const FooterNav = styled.nav`
position: relative;
display: flex;
gap: 10px;
z-index: 100;

@media screen and (min-width: 880px) {
  position: absolute;
  flex-direction: column;
  left: 50px;
  bottom: 50px;
}
`

const FooterLink = styled.a`
color: ${({ theme }) => theme.linkColor};
text-decoration: none;
font-size: 12px;

&:hover {
  color: ${({ theme }) => theme.linkColorHover};
  text-decoration: underline;
}
`

export interface BackgroundProps {
  [key: string]: any;
}


export const Background = ({
  ...props
}: BackgroundProps) => <BackgroundContainer {...props}>
  <Logo src={ViduaLogo} />
  <Content>{ props.children }</Content>
  <FooterNav>
    <FooterLink href={'https://vidua.nl/aanmelden/'}>Aanmelden bij Vidua</FooterLink>
    <FooterLink href={'https://vidua.nl/overvidua/'}>Wat is Vidua?</FooterLink>
    <FooterLink href={'https://vidua.nl/hulp/'}>Hulp en klantenservice</FooterLink>
  </FooterNav>
</BackgroundContainer>

export default Background;