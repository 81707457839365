import 'core-js/proposals/promise-with-resolvers';
import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Validate from "../validate";

import autorenew from "./assets/autorenew.svg";
import deleteSvg from "./assets/delete.svg";
import open from "./assets/open.svg";

import st from "./no-preview-error.module.scss";
import s from "./pdf-preview.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `/lib/pdf.worker.js`;

const PdfPreview = ({
  pdfFile,
  setPdfFile,
  signed,
  setUploadResData,
  view,
  isPanelOpen,
  setIsPanelOpen,
  isLoading,
  response,
  error,
  setShowUserErrorWithPDF,
  setShowUserError,
}) => {
  const { t } = useTranslation();
  const [disableLink, setDisableLink] = useState(false);

  const NoPreviewError = () => {
    const { t } = useTranslation();

    useEffect(() => {
      sessionStorage.setItem("noFullPreview", "true");
      setDisableLink(true);

      return () => {
        sessionStorage.setItem("noFullPreview", "false");
        setDisableLink(false);
      };
    }, []);

    return <div className={st.content}>{t("pdf-preview.errNonPDF")}</div>;
  };

  return (
    <>
      {!signed && (
        <>
          <div className={s.line}></div>
          <div className={s.container}>
            <div style={{ zIndex: "3" }}>
              <Link
                to={{
                  pathname: "/pdf-preview",
                }}
                className={s.page}
                style={{
                  pointerEvents: disableLink ? "none" : "auto",
                }}
              >
                <Document file={pdfFile} error={<NoPreviewError />}>
                  <Page
                    pageNumber={1}
                    width={157.84}
                    renderAnnotationLayer={false}
                  ></Page>
                </Document>
              </Link>
              <div className={s.buttons}>
                <Link
                  id="maximize_preview"
                  to={{
                    pathname: "/pdf-preview",
                  }}
                  className={s.linkImg}
                  style={{
                    pointerEvents: disableLink ? "none" : "auto",
                    display: disableLink ? "none" : "block",
                  }}
                >
                  <img src={open} style={{ marginBottom: "2px" }} alt="open" />
                </Link>
                <img
                  src={autorenew}
                  style={{ display: "none" }}
                  alt="autorenew"
                />
                <img
                  id="empty_preview"
                  src={deleteSvg}
                  alt="delete"
                  onClick={() => {
                    sessionStorage.removeItem("restoreFile");
                    sessionStorage.removeItem("pdfUrl");
                    sessionStorage.removeItem("fileName");
                    sessionStorage.removeItem("pdfFile");
                    sessionStorage.removeItem("signErr");
                    setShowUserErrorWithPDF(false);
                    setShowUserError(false);
                    setPdfFile(null);
                    setUploadResData(null);
                  }}
                />
              </div>
            </div>
          </div>
          <Validate
            view={view}
            isPanelOpen={isPanelOpen}
            setIsPanelOpen={setIsPanelOpen}
            isLoading={isLoading}
            response={response}
            error={error}
            setShowUserErrorWithPDF={setShowUserErrorWithPDF}
            setShowUserError={setShowUserError}
          />
        </>
      )}
      {signed && (
        <>
          <div className={s.line2}></div>
          <div className={s.containerSign}>
            <div style={{ zIndex: "3" }}>
              <div className={s.page}>
                <Document
                  file={pdfFile}
                  error={
                    <div className={st.content}>{t("pdf-preview.signPDF")}</div>
                  }
                >
                  <Page
                    pageNumber={1}
                    width={157.84}
                    renderAnnotationLayer={false}
                  ></Page>
                </Document>
              </div>
              <div style={{ display: "none" }} className={s.link}>
                Hoe kan ik dit valideren?
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PdfPreview;
