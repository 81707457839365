import { useEffect, useRef, useState } from "react";

import verified from "./assets/verified.svg";
import warning from "./assets/warning.svg";
import err from "./assets/err.svg";
import plus from "./assets/plus.svg";
import minus from "./assets/minus.svg";
import s from "./signature-panel.module.scss";

const SignaturePanel = ({ setIsPanelOpen, response }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [key, setKey] = useState(false);

  const node = useRef(null);

  useEffect(() => {
    document.body.classList.add(s.hideBodyScroll);

    return () => {
      document.body.classList.remove(s.hideBodyScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  const handleClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      setIsPanelOpen(false);
    }
  };

  return (
    <div className={s.popupBackground}>
      <div className={s.popupContainer} ref={node}>
        <div className={s.header}>Signature panel</div>
        <div className={s.infoText}>
          We hebben {response?.validationsList?.length} handtekeningen gevonden:
        </div>
        <div className={s.main}>
          {response &&
            response?.validationsList?.map((sign, indx) => (
              <>
                <div
                  key={indx}
                  className={s.row}
                  style={{
                    color:
                      sign.indication === "INDETERMINATE"
                        ? "#E7B000"
                        : sign.indication === "TOTAL_FAILED"
                        ? "#EB3232"
                        : "#6FAC9D",
                    borderTopLeftRadius: indx === 0 ? "24px" : 0,
                    borderBottomRightRadius:
                      indx === response.validationsList.length - 1 && !showInfo
                        ? "24px"
                        : 0,
                  }}
                  onClick={() => {
                    if (indx === key) {
                      setShowInfo(!showInfo);
                    } else {
                      setShowInfo(true);
                    }
                    setKey(indx);
                  }}
                >
                  <div className={s.rightBox}>
                    <img
                      src={
                        sign.indication === "INDETERMINATE"
                          ? warning
                          : sign.indication === "TOTAL_FAILED"
                          ? err
                          : verified
                      }
                      alt="verified"
                    />
                    Handtekening {++indx}: Ondertekend door {sign.signedby}
                  </div>
                  <img
                    src={showInfo && key === indx ? minus : plus}
                    alt="plus"
                  />
                </div>
                {showInfo && key === indx && (
                  <div className={s.info}>
                    {/* 1 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Qualification1</div>
                      <div className={s.left}>
                        {sign?.signaturelevel}
                        {/* <img src={info} alt="info" /> */}
                      </div>
                    </div>
                    {/* 2 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Signature format</div>
                      <div className={s.left}>{sign?.signatureformat}</div>
                    </div>
                    {/* 3 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Indication</div>
                      <div className={s.left}>{sign?.indication}</div>
                    </div>
                    {/* 4 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Certificate Chain</div>
                      <div className={s.left}>
                        {sign?.certificatechainList[0]}
                      </div>
                    </div>
                    {sign?.certificatechainList.map((elem, i) => {
                      return i > 0 ? (
                        <div className={s.infoChainRow}>
                          <div className={s.left}>{elem}</div>
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                    {/* 5 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>On claimed time</div>
                      <div className={s.left}>{sign?.signingtime}</div>
                    </div>
                    {/* 6 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Best signature time</div>
                      <div className={s.left}>{sign?.bestsignaturetime}</div>
                    </div>
                    {/* 7 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Signature position</div>
                      <div className={s.left}>
                        {indx} out of {response?.validationsList?.length}
                      </div>
                    </div>
                    {/* 8 */}
                    <div className={s.infoRow}>
                      <div className={s.right}>Signature scope</div>
                      <div className={s.left}>
                        {sign?.signaturescopeList[0].name}
                      </div>
                    </div>
                    {sign?.signaturescopeList.map((elem, i) => {
                      return (
                        <div className={s.infoChainRow}>
                          <div className={s.left}>{elem?.scope}</div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ))}
        </div>
        <div className={s.closeBtnRow}>
          <div className={s.closeBtn} onClick={() => setIsPanelOpen(false)}>
            <div className={s.text}>Sluiten</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturePanel;
