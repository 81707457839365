import styled from "styled-components";
import { Link } from "react-router-dom";
import backgroundImage from "./assets/signing-background.png";
import { useTranslation } from "react-i18next";

const SigningBlock = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const translations = {
    nl: {
      title: "Een nieuw document ondertekenen",
      description:
        "Met Vidua Signing onderteken je een document digitaal, en is hij net zo officieel als een handtekening met pen op papier.",
      button: "Naar Vidua Signing",
    },
    en: {
      title: "Sign a new document",
      description:
        "With Vidua Signing you sign a document digitally, and it is just as official as a signature with pen on paper.",
      button: "To Vidua Signing",
    },
  };

  return (
    <Container>
      <Row>
        <Block>
          <Title>{translations[language].title}</Title>
          <Text>{translations[language].description}</Text>
          <Button to="/signing">{translations[language].button}</Button>
        </Block>
      </Row>
    </Container>
  );
};

export default SigningBlock;

const Container = styled.div`
  background: #f3f1ee;
  padding: 240px 20px 24px;
  background: url(${backgroundImage});
  background-position: center -180px;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${({ theme: { device } }) => device.mobile} {
    background-size: cover;
  }
  @media ${({ theme: { device } }) => device.laptop} {
    background-position: center;
    background-size: 1400px auto;
  }
`;

const Row = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const Block = styled.div`
  background: #fff;
  border-radius: 30px 0;
  padding: 30px;
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  @media ${({ theme: { device } }) => device.tablet} {
    // position: relative;
    // left: -70px;
    // width: 298px;
    padding: 50px;
    max-width: 560px;
    gap: 15px;
  }
`;

const Title = styled.h2`
  // @media (max-width: 375px) {
  //   font-weight: 600;
  //   font-size: 18px;
  //   line-height: 25px;
  // }
`;

const Text = styled.p`
  line-height: 1.6;
  margin-bottom: 5px;

  @media (max-width: 375px) {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
`;

const Button = styled(Link)`
  background: ${({ theme }) => theme.colors.salmon};
  color: #fff;
  padding: 15px 30px;
  border-radius: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.lightSalmon};
  }

  @media (max-width: 375px) {
    width: 190px;
    height: 52px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
`;
