/* eslint-disable */
import { useEffect } from "react";

export default (blockBtn, setBlockBtn) => {
  useEffect(() => {
    if (blockBtn) {
      const timer = setTimeout(() => {
        setBlockBtn(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [blockBtn]);
};
