import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {OpeningHours} from "@cleverbase/ui";
import axios from "axios";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const getOpeningHours = () => {
    // todo: define proper API URL
    return axios.get("/api/natural-person-registrations/opening-hours").then(res => res.data?.upcomingOpeningHours);
  }

  const workingOurs = {
    nl: {
      openingHours: "Openingstijden",
    },
    en: {
      openingHours: "Opening hours",
    },
  };

  return (
    <Background>
      <Container>
        <Column>
          <Header>Vidua B.V.</Header>
          <Row>Maanweg 174</Row>
          <Row>2516 AB Den Haag</Row>
          <Row>
            <a href="tel:+31708209680">(070) 820 96 80</a>
          </Row>
          <Row>
            <a href="mailto:klantenservice@vidua.nl">klantenservice@vidua.nl</a>
          </Row>
        </Column>
        <br />
        <br />

        <Column>
          <Header>{workingOurs[i18n.language].openingHours}</Header>
          <OpeningHours getOpeningHours={getOpeningHours} language={i18n.language} />
        </Column>

        <br />
        <br />
        <Column>
          <Header>{t("footer.moreInfo")}</Header>
          <Row>
            <a href={t("footer.termsConditions.url")} target="_blank" rel="noreferrer">
              {t("footer.termsConditions.label")}
            </a>
          </Row>
          <Row>
            <a href={t("footer.privacyStatement.url")} target="_blank" rel="noreferrer">
              {t("footer.privacyStatement.label")}
            </a>
          </Row>
          <Row>
            <a href={t("footer.certifications.url")} target="_blank" rel="noreferrer">
              {t("footer.certifications.label")}
            </a>
          </Row>
        </Column>
      </Container>
    </Background>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  @media ${({ theme: { device } }) => device.tablet} {
    flex-direction: row;
  }

  @media ${({ theme: { device } }) => device.ьщишду} {
    padding: 0px;
  }
`;

const Header = styled.span`
  font-weight: bold;
  margin-bottom: 12px;

  @media ${({ theme: { device } }) => device.mobile} {
    margin-bottom: 5px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.span`
  display: block;
  font-weight: normal;
`;

const Background = styled.div`
  width: 100%;
  display: block;
  min-height: 100px;
  color: #fff;
  background: ${({ theme }) => theme.colors.salmon};
  margin-top: 100px;
  padding-top: 65px;
  padding-bottom: 65px;

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${({ theme: { device } }) => device.mobile} {
    margin: 0;
    padding: 30px 34px 35px 24px;
  }
`;
