import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MAX_FILE_SIZE, MAX_LETTERS_IN_FILENAME, MAX_XML_SIZE } from "helpers";
import useMobile from "hooks/useMobile";

import checkbox from "./assets/checkbox.svg";
import checkboxUncheck from "./assets/uncheck-checkbox.svg";
import plus from "./assets/plus.svg";
import done from "./assets/done.svg";
import loaded from "./assets/loaded.svg";

import s from "./drag-drop.module.scss";
import useCongifContext from "hooks/useConfigContext";

const DragDrop = ({
  onDrop,
  checkboxSelected,
  setCheckboxSelected,
  signResData,
  setSignResData,
  setShowBlueScreen,
  blockBtn,
  handleDownloadClick,
  uploadResData,
  setShowPopup,
  setShowPopup2,
  pdfFile,
  isLoading,
  showUserError,
  showLoaderInHeader,
}) => {
  const config = useCongifContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const { t } = useTranslation();
  const isMobile = useMobile();
  const isPdfUrl = !!sessionStorage.getItem("pdfUrl");
  const isPdfFile = !!sessionStorage.getItem("pdfFile");
  const isPdf = isPdfUrl || isPdfFile;
  const isBeforeUploadPdf =
    !signResData && !uploadResData && !showLoaderInHeader;
  const isOnLoadPdf = showLoaderInHeader;
  const isAfterUploadPdf = !signResData && uploadResData;
  const isAfterSign = signResData;
  const disableLink = sessionStorage.getItem("noFullPreview") === "true";

  const files = acceptedFiles.map((file, index) => {
    if (
      file.size > MAX_FILE_SIZE ||
      (file.type === "text/xml" && file.size > MAX_XML_SIZE)
    ) {
      sessionStorage.removeItem("fileName");
      uploadResData = null;
      return null;
    }

    return (
      <>
        {showTooltip && !isMobile && (
          <div className={s.tooltip}>{file.path}</div>
        )}
        <div
          style={{
            overflow: "auto",
            maxHeight: "88px",
          }}
          onMouseOver={(e) =>
            file.path.length > MAX_LETTERS_IN_FILENAME
              ? setShowTooltip(true)
              : null
          }
        >
          {file.path}
        </div>
      </>
    );
  });

  const renderHeader = () => (
    <>
      {isBeforeUploadPdf && (
        <div className={s.header} {...getRootProps()}>
          <input {...getInputProps()} multiple={false} id="uploadFile" />
          <img src={plus} className={s.headerImg} alt="plus" />
          {t("drag-drop.header")}
        </div>
      )}

      {isOnLoadPdf && (
        <div className={s.header} {...getRootProps()}>
          <input {...getInputProps()} multiple={false} id="uploadFile" />
          <img src={loaded} className={s.headerImgRoutate} alt="loaded" />
          {t("drag-drop.header4")}
        </div>
      )}

      {isAfterUploadPdf && (
        <div className={s.header} {...getRootProps()}>
          <input {...getInputProps()} multiple={false} />
          <img src={loaded} className={s.headerImg} alt="loaded" />
          {t("drag-drop.header2")}
        </div>
      )}

      {isAfterSign && (
        <div className={s.header + " " + s.signed} {...getRootProps()}>
          <img src={done} className={s.headerImg} alt="done" />
          {t("drag-drop.header3")}
        </div>
      )}
    </>
  );

  const renderFileName = () => (
    <div
      className={s.selectContainer}
      style={{
        background: isBeforeUploadPdf
          ? !!sessionStorage.getItem("pdfFile")
            ? "#A9B7D7"
            : "#E5E9F3"
          : signResData
          ? "#6FAC9D"
          : "#A9B7D7",
      }}
      onMouseLeave={(e) => setShowTooltip(false)}
    >
      <section>
        {isBeforeUploadPdf && !isPdf && (
          <div
            className={
              !!sessionStorage.getItem("fileName") ? s.drag : s.fileNameGreen
            }
            style={{
              color: !!sessionStorage.getItem("fileName")
                ? "#08476F"
                : "#949494",
            }}
          >
            {!!sessionStorage.getItem("fileName")
              ? sessionStorage.getItem("fileName")
              : t("drag-drop.defaultStatus")}
          </div>
        )}
        {(isAfterUploadPdf || isPdf) && !isAfterSign && (
          <Link
            to={{
              pathname: "/pdf-preview",
            }}
            className={
              sessionStorage.getItem("fileName") ===
              sessionStorage.getItem("documentName")
                ? s.fileNameGreen
                : s.fileName
            }
            style={{
              pointerEvents: disableLink ? "none" : "auto",
            }}
          >
            <div className={s.filenameContainer}>
              <div className={s.filenameContainerName}>
                {files.length > 0
                  ? files
                  : sessionStorage.getItem("fileName") === "null"
                  ? ""
                  : sessionStorage.getItem("fileName")}
              </div>
              {isMobile && !isAfterSign && (
                <div className={s.prompt}>{t("drag-drop.prompt")}</div>
              )}
            </div>
          </Link>
        )}
        {isAfterSign && (
          <div className={s.fileNameGreen}>
            <div className={s.fileNameGreenText}>
              {sessionStorage.getItem("fileName")}
            </div>
          </div>
        )}
      </section>
    </div>
  );

  return (
    <div
      className={s.dragndrop}
      style={{
        borderRadius:
          pdfFile || isPdfUrl || isAfterSign
            ? !isMobile
              ? "0 0 56px 0"
              : "25px 0"
            : "56px 0",
      }}
    >
      {/* drop area */}
      {renderHeader()}
      {renderFileName()}
      {isAfterSign && (
        <div className={s.howItWorkContainer}>
          <a onClick={() => setShowPopup2(true)} href="#howitworks">
            {t("drag-drop.howItWorkContainer")}
          </a>
        </div>
      )}

      {/* checkbox */}
      {!isAfterSign && (
        <div className={s.allowCheckBoxContainer}>
          <img
            id="tc_radiobutton"
            src={checkboxSelected ? checkbox : checkboxUncheck}
            onClick={() => {
              sessionStorage.setItem("checkboxSelected", !checkboxSelected);
              setCheckboxSelected(!checkboxSelected);
            }}
            className={s.checkboxImg18}
            alt="checkbox"
            data-testid="checkbox"
          />

          <div>
            <span
              className={s.checkboxText}
              onClick={() => {
                sessionStorage.setItem("checkboxSelected", !checkboxSelected);
                setCheckboxSelected(!checkboxSelected);
              }}
              data-testid="checkbox-text"
            >
              {t("drag-drop.checkboxText")}
            </span>
            <span className={s.linkToPdf} onClick={() => setShowPopup(true)}>
              {t("drag-drop.linkToPdf")}
            </span>
          </div>
        </div>
      )}

      {/* download btn */}
      {!isAfterSign && (
        <div className={s.btnContainer}>
          <button
            id="sign_button"
            className={
              !checkboxSelected ||
              !sessionStorage.getItem("fileName") ||
              sessionStorage.getItem("signErr") === "true" ||
              isLoading ||
              showUserError ||
              showLoaderInHeader
                ? s.btnDisable
                : s.btn
            }
            onClick={() => {
              if (
                !(
                  !checkboxSelected ||
                  !sessionStorage.getItem("fileName") ||
                  sessionStorage.getItem("signErr") === "true" ||
                  isLoading ||
                  showUserError ||
                  showLoaderInHeader
                )
              ) {
                setShowBlueScreen(true);
                sessionStorage.setItem("showBlueScreen", "true");
                sessionStorage.setItem("signComplete", "false");
                return (window.location.href = `${config.REACT_APP_ESIGN_PORTAL_URL}response_type=code&client_id=${config.REACT_APP_CLIENT_ID}&redirect_uri=${config.REACT_APP_PORTAL_URL}/sign-callback&scope=service&lang=en-US&state=12345678`);
              }
            }}
            disabled={!checkboxSelected || !sessionStorage.getItem("fileName")}
            data-testid="download-btn"
          >
            <div className={s.text}>{t("drag-drop.btnContainer")}</div>
          </button>
        </div>
      )}

      {isAfterSign && (
        <div className={s.btnContainer}>
          <button
            id="download_button"
            className={
              (blockBtn ? s.btnDisable : s.btn2) + " " + s.topLeftRound
            }
            onClick={() => handleDownloadClick()}
            disabled={blockBtn}
            data-testid="download-btn"
          >
            <div className={s.text}>{t("drag-drop.btnContainerAfterSign")}</div>
          </button>
          <div className={s.cancelWrapper} {...getRootProps()}>
            <input {...getInputProps()} multiple={false} id="uploadFile" />
            <button
              id="sign_new_button"
              className={s.cancelButton + " " + s.bottomRightRound}
              onClick={() => {
                setSignResData(null);
                sessionStorage.removeItem("restoreFile");
                sessionStorage.removeItem("pdfUrl");
                sessionStorage.removeItem("fileName");
                sessionStorage.removeItem("signComplete");
                sessionStorage.removeItem("pdfFile");
                sessionStorage.removeItem("noFullPreview");
              }}
              data-testid="cancel-button"
            >
              {t("drag-drop.cancelButton")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragDrop;
