import { useTranslation } from "react-i18next";
import useMobile from "hooks/useMobile";
import photo from "./assets/photo.jpg";
import s from "./system-error.module.scss";

const SystemError = ({
  setShowError,
  errorCode,
  setShowSystemError,
  showSystemError,
}) => {
  const isMobile = useMobile();
  const { t } = useTranslation();

  if (errorCode === "" && showSystemError?.length > 0) {
    errorCode = showSystemError;
  }

  return (
    <div className={s.background}>
      <div className={s.errorContainer}>
        <div className={s.headerContainer}>
          <div className={s.errCode}>
            {t("system-error.header1")} [{errorCode}]
          </div>
          <div className={s.text}>{t("system-error.header2")}</div>
        </div>
        <div className={s.main}>
          {t("system-error.text1")} <br />
          <br />
          {t("system-error.text2")}{" "}
          <a href={!isMobile ? "https://vidua.nl/hulp/" : "tel:0708209682"}>
            <u>{t("system-error.text3")}</u>
          </a>{" "}
          {t("system-error.text4")}
        </div>
        <img className={s.photo} src={photo} alt="background for system error" />
        <div className={s.closeBtnRow}>
          <div
            className={s.closeBtn}
            onClick={() => {
              setShowError(false);
              setShowSystemError(false);
              sessionStorage.removeItem("err");
            }}
          >
            <div className={s.text}>{t("system-error.closeBtn")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemError;
