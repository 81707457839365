import { createSlice } from "@reduxjs/toolkit";

export const SESSION_WARN_TIME_KEY = "session-warn-time";
export const SESSION_WARN_DELAY = 20 * 60 * 1000; // minutes
export const SESSION_MAX = 30 * 60 * 1000; // minutes
const storedSessionWarningTime = sessionStorage.getItem(SESSION_WARN_TIME_KEY);
const initialState = {
  warningTime: storedSessionWarningTime
    ? Number(storedSessionWarningTime)
    : null,
  warning: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    startSession: (state) => {
      const warningTime = Date.now() + SESSION_WARN_DELAY;
      state.warningTime = warningTime;
      state.warning = false;
      sessionStorage.setItem(SESSION_WARN_TIME_KEY, String(warningTime));
    },
    stopSession: (state) => {
      sessionStorage.removeItem(SESSION_WARN_TIME_KEY);
      state.warningTime = null;
      state.warning = false;
    },
    showWarning: (state) => {
      state.warning = true;
    },
    hideWarning: (state) => {
      state.warning = false;
    }
  }
});

export const { startSession, stopSession, showWarning, hideWarning } = sessionSlice.actions;
export default sessionSlice.reducer;
