export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_XML_SIZE = 2 * 1024 * 1024;
export const MAX_LETTERS_IN_FILENAME = 39;

export function stringToJSON(str) {
  let result = {};
  try {
    result = JSON.parse(str);
    result = result === null ? (result = {}) : result;
  } catch (e) {
  } finally {
    return result;
  }
}

export function translateToDutch(str) {
  const POSSIBLE_ERRORS = {
    "The provided token is invalid": "Het opgegeven token is ongeldig",
    "The provided document is invalid": "Het verstrekte document is ongeldig",
    "The provided filename is invalid": "De opgegeven bestandsnaam is ongeldig",
    "The provided code is invalid": "De opgegeven code is ongeldig",
    "The provided field is invalid": "Het opgegeven veld is ongeldig",
    "The provided file does not exist": "Het opgegeven bestand bestaat niet",
    "File size exceeds limit!": "De bestandsgrootte overschrijdt de limiet!",
    "Document is empty": "Het document is leeg",
    "Token type or access token not found":
      "Het tokentype of toegangstoken niet gevonden",
    "Token type not supported": "Het tokentype wordt niet ondersteund",
    "Choose correct pdf file": "Kies het juiste pdf-bestand",
    "File size exceeds limit": "De bestandsgrootte overschrijdt de limiet",
    "Token has been expired": "Token is niet meer geldig",
    "File could not be found": "Bestand kan niet worden gevonden",
    "Internal server error": "Interne server fout",
    "Signature is not valid": "Handtekening is niet geldig",
    "Invalid signature exists. Document could not be signed":
      "Had document kan niet ondertekend worden. Het document bevat een ongeldige handtekening",
    "Non-flat document could not be signed":
      "Het document kon niet ondertekend worden. De records in de PDF moeten een eendimensionale structuur hebben (flat file)",
    "Document is not proper PDF":
      "Het document kan niet als PDF worden herkend. Controleer het bestand en probeer opnieuw",
  };

  return POSSIBLE_ERRORS[str] || str;
}

export const downloadStringToFile = ({ filename, content }) => {

  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));

  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
