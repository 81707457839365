import { Switch, Route } from "react-router-dom";
import Dashboard from "features/dashboard-page";
import SigningPage from "features/home-page";
import PdfPreviewPage from "features/pdf-preview-page";
import { clearSession, setToken, setUser, setVars } from './app/globalSlice';
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from "react";
import CallbackIdf from "CallbackIdf";
import SessionWarning from "./features/session";
import React from 'react';

const Routes = () => (
  <Switch>
    <SetVars>
      <Route path="/idf-callback" component={CallbackIdf} />
      <PrivateRoute>
        <SessionWarning/>
        <Route path="/" component={Dashboard} exact />
        <Route path="/sign-callback" component={SigningPage} />
        <Route path="/signing" component={SigningPage} />
        <Route path="/pdf-preview" component={PdfPreviewPage} />
      </PrivateRoute>
    </SetVars>
  </Switch>
);

function SetVars({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch('/config/config.json')
      .then(response => response.json())
      .then(data => {
        window.envVars = data;
        dispatch(setVars(data));
      });
  }, [dispatch]);
  return children;
}

function PrivateRoute({ children }) {
  const isValidToken = token => typeof token === 'string' && token.length > 9;
  const [isLoggedin, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector(state => state.global);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if(token && isValidToken(token)) {
      dispatch(setToken(token));
      try {
        const payload = JSON.parse(sessionStorage.getItem('user'));
        if(payload) dispatch(setUser(payload));
      } catch (error) {
        console.error(error);
        sessionStorage.removeItem('user');
      }
      setLogin(true);
    } else {
      clearSession();
    }

    setTimeout(() => { // OMG what have we done... We will fix this asap.
      setLoading(false);
    }, 1500);
  }, [dispatch]);

  if(loading) return <>Loading...</>;

  return token || isLoggedin ? children : <LoginView />;
}

function LoginView() {
  const { loginUrl } = useSelector(state => state.global);
  if (loginUrl) window.location.href = loginUrl;
  return <a href={loginUrl} style={{ display: 'none' }}>Login</a>
}

export default Routes;
