import chroma from "chroma-js";
import colors from "./_colors";
import defaultTheme from "./../defaultTheme";
import { mergeDeep } from "./../../utils";

const { blue } = colors;

let importUrls: Array<string> = [
  "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap",
];

const theme = mergeDeep(defaultTheme, {
  importUrls,
  defaultFont: `"Nunito Sans", sans-serif`,
  headerFont: `"Nunito Sans", sans-serif`,
  primaryColor: blue,
  background: blue,
  linkColor: blue,
  linkColorHover: `${chroma(blue).brighten()}`,
});

export default theme;
