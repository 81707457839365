import colors from "./_colors.module.scss";

const viduaColors = {
    red: colors.red,
    softRed: colors.softRed,
    salmon: colors.salmon,
    softSalmon: colors.softSalmon,
    darkBlue: colors.darkBlue,
    blue: colors.blue,
    softBlue: colors.softBlue,
    lightBlue: colors.lightBlue,
    darkYellow: colors.darkYellow,
    yellow: colors.yellow,
    softYellow: colors.softYellow,
    lightYellow: colors.lightYellow,
    green: colors.green,
    softGreen: colors.softGreen,
    purple: colors.purple,
    softPurple: colors.softPurple,
    lightPurple: colors.lightPurple,
    grey: colors.grey,
    softGrey: colors.softGrey,
    black: colors.black,
    white: colors.white,
    whiteTransparent: colors.whiteTransparent,
    softBlack: colors.softBlack,
    lightGrey: colors.lightGrey,
    darkGrey: colors.darkGrey,
    rainbowGradient: colors.rainbowGradient,
    blueGradient: colors.blueGradient,
}

export default viduaColors;