import { configureStore } from "@reduxjs/toolkit";
import homePageReducer from "../features/home-page/home-page-slice";
import globalReducer from "./globalSlice";
import sessionReducer from "../features/session/session-slice";

export const store = configureStore({
  reducer: {
    homePage: homePageReducer,
    global: globalReducer,
    session: sessionReducer
  },
});
