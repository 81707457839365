/*eslint-disable*/
import { useEffect, useState } from "react";

import { PreviewRequest } from "protos/document_pb";
import useGRPCCLientContext from "hooks/useGRPCClientContext";

export default () => {
  const { clients } = useGRPCCLientContext();
  const [showLoader, setShowLoader] = useState(false);
  const isNeedToRestorePDF =
    sessionStorage.getItem("restoreFile") === "y" ? true : false;

  useEffect(() => {
    if (isNeedToRestorePDF) {
      setShowLoader(true);
      const req = new PreviewRequest();
      req.setToken(sessionStorage.getItem("token"));
      req.setFileid(sessionStorage.getItem("credentials"));

      clients.documentPromiseClient
        .preview(req, {})
        .then((res) => {
          const { url } = res.toObject();
          sessionStorage.setItem("pdfUrl", url);
        })
        .catch((err) => console.log({ err }))
        .finally(() => setShowLoader(false));
    }
  }, []);

  return showLoader;
};
