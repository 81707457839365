import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SettingsModal from "components/modal-settings";

import s from "./navigation.module.scss";
import flagNL from "./assets/flag1.svg";
import flagEN from "./assets/flag2.svg";
import userProfile from "./assets/user-profile.svg";
import styled from "styled-components";
import DropDown from "components/dropdown";
import { logout } from "app/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UserName = styled.span`
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;

@media (max-width: 561px) {
  display: none;
}
`

const Navigation = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const [showSettingPopup, setShowSettingsPopup] = useState(false);
  const { pathname } = useLocation();
  const { user, anonymous } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const translations = {
    nl: {
      anonymous: "Anonieme gebruiker",
    },
    en: {
      anonymous: "Anonymous user",
    },
  };

  const setLanguage = (lng) => {
    changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const userName = () => {
    const name = `${user.givenname || ""} ${user.lastname || ""}`.trim();

    return (
      <>
        <img src={userProfile} alt="user icon" />
        <UserName>{anonymous ? translations[language].anonymous : name}</UserName>
      </>
    );
  };

  return (
    <>
      {showSettingPopup && (
        <SettingsModal setShowSettingsPopup={setShowSettingsPopup} />
      )}
      <NavigationMenu id="navigation">
        {pathname === "/signing" && (
          <BackDashboard>
            <Link to="/">
              <BackIcon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"/></svg>
              </BackIcon>
              <BackLabel>{t("header.backButton")}</BackLabel>
            </Link>
          </BackDashboard>
        )}
        <DropDown
          label={userName()}
          options={[
            {
              handleClick: () => setShowSettingsPopup(!showSettingPopup),
              label: t("header.configSigning"),
            },
            {
              handleClick: () => dispatch(logout()),
              label: t("header.logout"),
              logoutIcon: true,
            },
          ]}
        ></DropDown>
        <DropDown
          label={
            <img
              src={language === "nl" ? flagNL : flagEN}
              alt="language flag"
            />
          }
          options={[
            {
              handleClick: () => setLanguage("nl"),
              label: (
                <>
                  <img src={flagNL} alt="Dutch flag" className={s.arrow} />{" "}
                  Nederlands
                </>
              ),
            },
            {
              handleClick: () => setLanguage("en"),
              label: (
                <>
                  <img src={flagEN} alt="British flag" className={s.arrow} />{" "}
                  English
                </>
              ),
            },
          ]}
        ></DropDown>
      </NavigationMenu>
    </>
  );
};

export default Navigation;

const NavigationMenu = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.textColor};
`;

const BackDashboard = styled.span`
  height: 100%;
  display: inline-flex;
  align-items: center;

  svg {
    margin-top: 4px;
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.colors.link};
  }

  a {
    height: 100%;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.link};
    display: inline-flex;
    align-items: center;

    &:hover {
      background: ${({ theme }) => theme.colors.navigationHoverBackground};
    }
  }
`;

const BackIcon = styled.span`
display: initial;

@media ${({ theme: { device }}) => device.tablet} {
  display: none;
}
`

const BackLabel = styled.span`
display: none;

@media ${({ theme: { device }}) => device.tablet} {
  display: initial;
}
`
