import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  ui: {
    userError: {
      showUserError: !!localStorage.getItem("error"),
      showUserErrorWithPDF: false,
    },
  },
};

export const homePageSlice = createSlice({
  name: "home-page",
  initialState,
  reducers: {
    changeUIUserError: (state, { payload }) => {
      state.ui.userError = { ...state.ui.userError, ...payload };
    },
  },
});

export const { incrementByAmount, changeUIUserError } = homePageSlice.actions;
export default homePageSlice.reducer;
