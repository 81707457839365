import {ITranslate, SupportedLanguages, TranslationType} from "./types";
import en from './translations/en.json';
import nl from './translations/nl.json';

const translations: TranslationType = {
  [SupportedLanguages.en]: en,
  [SupportedLanguages.nl]: nl,
}

export const weekdayFormat = (date: string, t: (term: string) => any): string => {
  const weekdays = t('weekdays');
  const months = t('months');
  const d = new Date(date);
  const weekday: string = weekdays[d.getDay()];
  const day: number = d.getDate();
  const month: string = months[d.getMonth()];
  return `${weekday} ${day} ${month}`;
}

export const translate = (language: SupportedLanguages): ITranslate =>
  (term: string): any => translations[language][term] || term;
