import Routes from "./routes";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { GRPCClientsProvider } from "contexts/grpc-clients";
import { ThemeProvider } from "styled-components";
import "./i18n";
import "./styles/styles.scss";
import { ConfigProvider } from "contexts/config";

const salmon = "#ff976e";

const breakpoints = {
  // s: 375,
  // m: 800,
  // l: 1000,
  // xl: 1200
  mobile: "375px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobile: `(min-width: ${breakpoints.mobile})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  laptop: `(min-width: ${breakpoints.laptop})`,
  laptopL: `(min-width: ${breakpoints.laptopL})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
};

export const theme = {
  colors: {
    red: "#eb3232",
    softRed: "#f59898",
    salmon,
    lightSalmon: "#FFAC8B",
    darkSalmon: "#E56D3E",
    softSalmon: "#ffc1b5",
    darkBlue: "#08476f",
    blue: "#005f9c",
    softBlue: "#a9b7d7",
    lightBlue: "#e5e9f3",
    darkYellow: "#9e6d0e",
    yellow: "#e7b000",
    softYellow: "#f5d690",
    lightYellow: "#feeacd",
    green: "#6fac9d",
    softGreen: "#adcdc4",
    purple: "#8e70e3",
    softPurple: "#c8bbec",
    lightPurple: "#f4f1fc",
    grey: "#98999c",
    softGrey: "#e5e5e5",
    black: "#000",
    white: "#fff",
    whiteTransparent: "rgba(255, 255, 255, 0.65)",
    softBlack: "#222",
    lightGrey: "#eee",
    darkGrey: "#565656",
    _gray: "#949494",
    navigationHoverBackground: "#f2f2f2",
    link: "#005f9c",
    linkColor: "#288DCE",
    linkColorHover: "#54a4d8",
    textColor: "#333",
    rainbowGradient: `linear-gradient(90deg, #eb3232 0%, ${salmon} 24.88%, #e1b008 50.37%, #6fac9d 75.26%, #005f9c 100% )`,
  },
  breakpoints,
  device,
  containerWidth: "1200px",
};

fetch("/config/config.json")
  .then((response) => response.json())
  .then((data) => {
    window.envVars = data;
    const root = createRoot(document.getElementById("root"));
    root.render(
      <ConfigProvider>
        <GRPCClientsProvider data={data}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Provider>
          </ThemeProvider>
        </GRPCClientsProvider>
      </ConfigProvider>
    );
  });
