import {useEffect, useState} from "react";
import {SESSION_MAX, SESSION_WARN_DELAY, showWarning, startSession} from "./session-slice";
import {logout} from "../../app/globalSlice";
import {useDispatch, useSelector} from "react-redux";
import s from "./session.module.scss";
import {useTranslation} from "react-i18next";

const SessionWarning = () => {
  const dispatch = useDispatch();
  const { warningTime, warning } = useSelector(state => state.session);
  const [timeLeft, setTimeLeft] = useState('');
  const { t } = useTranslation('', {keyPrefix: 'session'});

  // automatic logout after session expiration
  useEffect(() => {
    let logoutTimer;
    if (warningTime) {
      const timeToLogout = warningTime + SESSION_MAX - SESSION_WARN_DELAY - Date.now();
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => dispatch(logout()), timeToLogout);
    }

    return () => clearTimeout(logoutTimer);
  }, [dispatch, warningTime]);

  // show session warning popup
  useEffect(() => {
    let warningTimer;
    if (warningTime) {
      clearTimeout(warningTimer);
      const timeToWarn = warningTime - Date.now();
      warningTimer = setTimeout(() => dispatch(showWarning()), timeToWarn);
    }

    return () => clearTimeout(warningTimer);
  }, [dispatch, warningTime]);

  // run countdown till logout
  useEffect(() => {
    let timeLeftInterval;
    if (warning) {
      clearInterval(timeLeftInterval);
      timeLeftInterval = setInterval(() => {
        const timeToLogout = Math.floor((warningTime + SESSION_MAX - SESSION_WARN_DELAY - Date.now()) / 1000);
        const minutes = Math.floor(timeToLogout / 60);
        const seconds = timeToLogout - minutes * 60;
        setTimeLeft(String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'));
      }, 1000);
    }
    return () => clearInterval(timeLeftInterval);
  }, [warning, warningTime]);

  const prolongSession = () => dispatch(startSession());
  const endSession = () => dispatch(logout());

  if (!warning) return null;

  return (
    <div className={s.popupBackground}>
      <div className={s.popupContainer}>
        <div className={s.header} data-testid="SessionPopupHeader">
          {t("header")}
        </div>
        <div className={s.text}>
          {t("text")}
        </div>
        <div className={s.buttons}>
          <button className={s.prolong} onClick={prolongSession}>
            {t("prolong")}
          </button>
          <button className={s.logout} onClick={endSession}>
            {t("logout")}
          </button>
        </div>
        <div className={s.timer}>
          {t("counter")} {timeLeft}
        </div>
      </div>
    </div>
  );
}
export default SessionWarning;
