import { useEffect, useState } from "react";

import { useMedia } from "react-media";

function useMobileVersion() {
  const isSmallScreen = useMedia({ query: "(max-width: 850px)" });
  const [mobile, setMobile] = useState(isSmallScreen);

  useEffect(() => {
    setMobile(isSmallScreen);
  }, [isSmallScreen]);

  return mobile;
}

export default useMobileVersion;
