import { useState, useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { TokenRequest } from "protos/auth_pb";
import { AuthorizationPromiseClient } from "protos/auth_grpc_web_pb";
import { useDispatch } from "react-redux";
import { login, setUser, setToken } from "app/globalSlice";
import styled from "styled-components";
import {startSession} from "./features/session/session-slice";


const ErrorContainer = styled.div`
display: flex;
margin: 80px auto;
max-width: 560px;
flex-direction: column;
`

const ErrorTitle = styled.h1`
line-height: 2;
`

const ErrorDescription = styled.p`
line-height: 1.6;
`

const CallbackIdf = () => {
    const [redirectUrl, setRedirectUrl] = useState(false);
    const dispatch = useDispatch();
    const queryString = useMemo(() => new URLSearchParams(window.location.search), []);
    const error = queryString.get('error');
    const error_description = queryString.get('error_description');
    const { REACT_APP_GRPC_ADDRESS } = window.envVars;

    useEffect(() => {
        if(!queryString.has('code')) return setRedirectUrl('/');
    
        const client = new AuthorizationPromiseClient(REACT_APP_GRPC_ADDRESS)
        const request = new TokenRequest();
        request.setCode(queryString.get('code'));

        client.login(request, {})
            .then(response => {
                const { token, user } = response.toObject();
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('user', JSON.stringify(user));
                dispatch(setUser(user));
                dispatch(setToken(token));
                dispatch(startSession());
                window.dispatchEvent(new Event("storage"));
                setRedirectUrl('/');
            })
            .catch(err => {
                console.error(err);
                dispatch(login());
            });

    }, [dispatch, queryString, REACT_APP_GRPC_ADDRESS]);
    

    if(queryString.has('error')) return <ErrorContainer>
        <ErrorTitle>Oops...</ErrorTitle>
        <ErrorDescription><b>Error: {error}</b><br/>{error_description}</ErrorDescription>
    </ErrorContainer>;

    return redirectUrl ? <Redirect to={redirectUrl} /> : <p>Loading...</p>;
}

export default CallbackIdf