/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
 export function isObject(item: any): boolean {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
/*
export function mergeDeep(target: any, ...sources: any[]): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
*/




function isMergeableObject(val: any) {
  var nonNullObject = val && typeof val === 'object'

  return nonNullObject
      && Object.prototype.toString.call(val) !== '[object RegExp]'
      && Object.prototype.toString.call(val) !== '[object Date]'
}

function emptyTarget(val: any) {
  return Array.isArray(val) ? [] : {}
}

function cloneIfNecessary(value: any, optionsArgument: any): any {
  var clone = optionsArgument && optionsArgument.clone === true
  return (clone && isMergeableObject(value)) ? mergeDeep(emptyTarget(value), value, optionsArgument) : value
}

function defaultArrayMerge(target: any, source: any, optionsArgument: any) {
  var destination = target.slice()
  source.forEach(function(e: any, i: number) {
      if (typeof destination[i] === 'undefined') {
          destination[i] = cloneIfNecessary(e, optionsArgument)
      } else if (isMergeableObject(e)) {
          destination[i] = mergeDeep(target[i], e, optionsArgument)
      } else if (target.indexOf(e) === -1) {
          destination.push(cloneIfNecessary(e, optionsArgument))
      }
  })
  return destination
}

function mergeObject(target: any, source: any, optionsArgument: any) {
  var destination: any = {}
  if (isMergeableObject(target)) {
      Object.keys(target).forEach(function (key: any) {
          destination[key] = cloneIfNecessary(target[key], optionsArgument)
      })
  }
  Object.keys(source).forEach(function (key) {
      if (!isMergeableObject(source[key]) || !target[key]) {
          destination[key] = cloneIfNecessary(source[key], optionsArgument)
      } else {
          destination[key] = mergeDeep(target[key], source[key], optionsArgument)
      }
  })
  return destination
}

export function mergeDeep(target: any, source: any, optionsArgument: any) {
  var array = Array.isArray(source);
  var options = optionsArgument || { arrayMerge: defaultArrayMerge }
  var arrayMerge = options.arrayMerge || defaultArrayMerge

  if (array) {
      return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument)
  } else {
      return mergeObject(target, source, optionsArgument)
  }
}

mergeDeep.all = function mergeDeepAll(array: any, optionsArgument: any) {
  if (!Array.isArray(array) || array.length < 2) {
      throw new Error('first argument should be an array with at least two elements')
  }

  // we are sure there are at least 2 values, so it is safe to have no initial value
  return array.reduce(function(prev, next) {
      return mergeDeep(prev, next, optionsArgument)
  })
}