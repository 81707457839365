import React, {useEffect, useState} from 'react';
import {
  IClosingWindow,
  IClosingWindowsProps,
  IOpeningHoursProps,
  IWeekday, OpeningHoursPreviewProps,
  SupportedLanguages, WeekdayPreviewProps
} from "./types";
import {
  OpeningDay,
  DayDescription,
  ClosedDay,
  ClosingWindowsPreviewDiv,
  ClosingWindowsHeader,
  CustomerPreview,
  OpeningIndicator,
  OpeningDays
} from "./styles";
import {translate, weekdayFormat} from "./utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const WeekdayPreview = ({weekday, t}: WeekdayPreviewProps) => (
  <OpeningDay>
    <div>
      {weekdayFormat(weekday.date, t)}
      <DayDescription>{weekday.description}</DayDescription>
    </div>
    {weekday.isClosed && <ClosedDay>{t('Closed')}</ClosedDay>}
    {!weekday.isClosed && <div>{weekday.opensAt} - {weekday.closesAt}</div>}
  </OpeningDay>
);

const ClosingWindowsPreview = ({ closingWindows, t }: IClosingWindowsProps) => (
  <ClosingWindowsPreviewDiv>
    <ClosingWindowsHeader>{t('Closed for scheduled maintenance')}:</ClosingWindowsHeader>
    {closingWindows.map((win, i) => (
      <div key={i}>
        {weekdayFormat(win.date, t)} {t('from')} {win.startsAt} - {win.endsAt}
      </div>
    ))}
  </ClosingWindowsPreviewDiv>
);

const OpeningHoursPreview = ({
  isOpen,
  weekdays,
  closingWindows,
  t,
}: OpeningHoursPreviewProps) => {
  return (
    <CustomerPreview>
      <OpeningIndicator isOpen={isOpen}>
        <FontAwesomeIcon icon={faClock} />
        {isOpen && <div>{t('We are open')}</div>}
        {!isOpen && <div>{t('We are currently closed')}</div>}
      </OpeningIndicator>
      <div>{t('These are our opening hours for the next few days')}:</div>
      <OpeningDays>
        {(weekdays || []).map((day, i) => <WeekdayPreview key={i} weekday={day} t={t} />)}
      </OpeningDays>
      {!!closingWindows && closingWindows.length > 0 && (
        <ClosingWindowsPreview closingWindows={closingWindows} t={t} />
      )}
    </CustomerPreview>
  );
}

export const OpeningHours = ({
  getOpeningHours,
  language = SupportedLanguages.nl
}: IOpeningHoursProps) => {
  const [ weekdays, setWeekdays ] = useState<IWeekday[]>();
  const [ closingWindows, setClosingWindows ] = useState<IClosingWindow[]>();
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const t = translate(language);

  useEffect(() => {
    getOpeningHours()
      .then(upcomingOpeningHours => {
        if (!upcomingOpeningHours) return
        setIsOpen(upcomingOpeningHours.isOpen)
        setWeekdays(upcomingOpeningHours.weekdays)
        setClosingWindows(upcomingOpeningHours.closingWindows)
      })
      .catch(console.error);
  }, []);

  if (!weekdays) return null;
  return <OpeningHoursPreview isOpen={isOpen} weekdays={weekdays} closingWindows={closingWindows} t={t} />;
}

export default OpeningHours;
