import { useState } from "react";

import s from "./switch.module.scss";

const Switch = () => {
  const defaultValue = !!localStorage.getItem("stamp")
    ? localStorage.getItem("stamp") === "true"
      ? true
      : false
    : true;

  const [isToggled, setIsToggled] = useState(defaultValue);

  return (
    <div>
      <label className={s.toggleSwitch}>
        <input
          type="checkbox"
          checked={isToggled}
          onChange={() => {
            setIsToggled(!isToggled);
            localStorage.setItem("stamp", !isToggled);
          }}
        />
        <span className={s.switch} />
      </label>
    </div>
  );
};

export default Switch;
