import React from 'react';
import styled from 'styled-components';
import Logo from './../Logo';

const StyledDiv = styled.div<{
  padding?: string
}>`
box-shadow: 0 10px 25px -5px rgba(0,0,0,0.25);
border-width: 10px 0;
border-style: solid;
border-color: ${({ theme }) => theme.primaryColor};
background-color: white;
width: 100%;
display: flex;
flex-direction: column;
gap: 35px;
padding-top: 60px;
@media screen and (min-width: 600px) {
  border-radius: 50px;
  box-sizing: content-box;
  border-width: 10px;
  width: 320px;
}
@media screen and (min-width: 600px) and (min-height: 780px) {
  min-height: 600px;
}
@media screen and (min-width: 600px) and (min-height: 680px) {
  min-height: 560px;
}
@media screen and (min-width: 880px) {
  margin-top: 50px;
}
`

const BoxBody = styled.div<{
  padding?: string
}>`
  padding: ${({ padding = '0px 30px 50px' }) => padding};
  position: relative;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  min-height: 320px;
  text-align: center;
  flex-direction: column;

  p {
    margin-top: 0;
  }

  @media screen and (min-width: 560px) {
    flex-direction: row;
  }
`

const StyledHeader = styled.header<{
  padding?: string
}>`
  padding: ${({ padding = '0px' }) => padding};
  
  h1 {
    font-family: ${({ theme }) => theme.headerFont};
    text-align: center;
    font-weight: bold;
    display: block;
    font-size: 24px;
    margin: 0;
  }
`
const StyledFooter = styled.footer<{
  padding?: any
}>`
  padding: ${({ padding = '30px' }) => padding};
  text-align: center;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

export interface BoxProps {
  [key: string]: any;
  header?: string;
  footer?: string;
  start?: boolean;
}

function showHeader(header: any) {
  return (<StyledHeader>{ header === 'logo' ? <Center><Logo /></Center> : <h1>{ header }</h1> }</StyledHeader>)
}
function showFooter(footer: any) {
  return (<StyledFooter>
    { footer === 'logo' ? <Logo width={100} height={31} /> : <h1>{ footer }</h1> }
  </StyledFooter>)
}

export const Box = ({
  children,
  header,
  footer,
  start,
  ...props
}: BoxProps) => {

  return <StyledDiv {...props}>
    {header && showHeader(header)}
    <BoxBody>{children}</BoxBody>
    {footer && showFooter(footer)}
  </StyledDiv>
};

export default Box;
