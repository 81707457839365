import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useBackButton = (f) => {
  const history = useHistory();

  useEffect(() => {
    const callback = () => {
      if (history.action === "POP") {
        f();
      }
    };
    return callback;
  }, [history, f]);

  return;
};

export default useBackButton;