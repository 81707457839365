import { useEffect, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";

import s from "./modal-validate.module.scss";

const HowToValidateModal = ({ setShowPopup }) => {
  const node = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add(s.hideBodyScroll);

    return () => {
      document.body.classList.remove(s.hideBodyScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  const handleClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      setShowPopup(false);
    }
  };

  return (
    <>
      <div className={s.popupBackground}>
        <div className={s.popupContainer} ref={node}>
          <div className={s.header} data-testid="Popup2Header">
            {t("modal-validate.header")}
          </div>
          <div className={s.textContainer}>
            <Trans i18nKey="modal-validate.text">
              Een gekwalificeerde elektronische handtekening kan gevalideerd
              worden met bijvoorbeeld een recente versie van Adobe Acrobat
              Reader DC op Windows of Mac (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://helpx.adobe.com/acrobat/using/validating-digital-signatures.html"
              >
                instructie
              </a>
              ) .<br />
              <br /> Het is ook mogelijk een online validator te gebruiken:
              <br />
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ec.europa.eu/cefdigital/DSS/webapp-demo/validation"
              >
                https://ec.europa.eu/cefdigital/DSS/webapp-demo/validation
              </a>
              <br />
              of
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://validation.evrotrust.com/"
              >
                https://validation.evrotrust.com/
              </a>
              
            </Trans>
          </div>

          <div className={s.closeBtnRow}>
            <div className={s.closeBtn} onClick={() => setShowPopup(false)}>
              <div className={s.text}>{t("modal-validate.closeBtnRow")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToValidateModal;
