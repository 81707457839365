import RegisterNowBlock from "components/RegisterNowBlock";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Certificate from "components/certificate";

const Header2 = styled.h2`
  font-weight: 600;
  font-size: 24px;
  margin-top: 20px;
  width: 100%;
`;

const Header3 = styled.h3`
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  line-height: 2;
  width: 100%;
`;

const Label = styled.span`
  text-align: right;
  color: #ccc;
  font-size: 12px;
  grid-column: 2;
`;

const PersData = styled.span`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-column: 1;
`;

const PersonalInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PersonalInfoRow = styled.div`
  display: flex;
  gap: 6px;
  font-size: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 580px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2px;
    margin-bottom: 5px;
  }
`;

const Container = styled.div`
  max-width: 570px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 80px auto 0;
  align-items: flex-start;
  padding-top: 80px;

  @media screen and (max-width: 580px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ButtonLink = styled.a`
  color: ${({ theme }) => theme.colors.red};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-block;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 580px) {
    margin-bottom: 50px;
  }
`;

const MyData = () => {
  const { REACT_APP_BLOCK_LINK } = window.envVars;
  const { user, anonymous } = useSelector((state) => state.global);
  const { t } = useTranslation("translation", { keyPrefix: "mydata" });

  const dataRow = (value, label) => (
    <PersonalInfoRow>
      <PersData id={`personal-data-${label.replace(" ", "-").toLowerCase()}`}>
        {value}
      </PersData>
      <Label>{label}</Label>
    </PersonalInfoRow>
  );

  const defaultValue = t("notregistered");

  const nullableDataRow = (value, label) => {
    if (value === null || value === undefined || value === "") {
      return dataRow(defaultValue, label);
    }

    return dataRow(value, label);
  };

  return (
    <Container>
      {anonymous ? (
        <RegisterNowBlock />
      ) : (
        <>
          <Header3>{t("myInfo")}</Header3>
          <PersonalInfo id="personal-data">
            {user.givenname && dataRow(user.givenname, t("firstname"))}
            {user.lastname && dataRow(user.lastname, t("lastname"))}
            {user.gender && dataRow(user.gender, t("gender"))}
            {user.birthdate && dataRow(user.birthdate, t("birthdate"))}
            {user.birthplace && dataRow(user.birthplace, t("birthplace"))}
            {user.nationality && dataRow(user.nationality, t("nationality"))}
            {user.email && dataRow(user.email, t("email"))}
            {user.iddocumentnr &&
              dataRow(user.iddocumentnr, t("documentnumber"))}
            {nullableDataRow(
              user.iddocumentplaceofissuance,
              t("documentplaceofissuance")
            )}
            {nullableDataRow(
              user.iddocumentdateofissuance,
              t("documentdateofissuance")
            )}
          </PersonalInfo>
          {(user?.signingcertificate || user?.authenticationcertificate) && (
            <Header3 id="my-certificates">{t("myCertificates")}</Header3>
          )}
          {user?.signingcertificate && (
            <Certificate
              id={`signing-certificate`}
              title={t("signingcertificateLabel")}
              file={{
                content: user.signingcertificate,
                name: "signing_certificate.cer",
              }}
            />
          )}
          {user?.authenticationcertificate && (
            <Certificate
              id={`authentication-certificate`}
              title={t("authenticationcertificateLabel")}
              file={{
                content: user.authenticationcertificate,
                name: "authentication_certificate.cer",
              }}
            />
          )}
          <Header2>{t("blockHeader")}</Header2>
          <p>{t("blockDescription")}</p>
          <ButtonLink id="block-account" href={REACT_APP_BLOCK_LINK}>
            {t("blockButton")} >
          </ButtonLink>
        </>
      )}
    </Container>
  );
};

export default MyData;
