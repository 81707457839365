import styled from "styled-components";
import { useState } from "react";

const Question = ({ question, answer }) => {
  const [show, toggle] = useState(false);

  return (
    <Grid>
      <QuestionHeader onClick={() => toggle(!show)}>{question}</QuestionHeader>
      <ButtonToggle active={show} onClick={() => toggle(!show)} />
      <Answer show={show} dangerouslySetInnerHTML={{ __html: answer }} />
    </Grid>
  );
};

export default Question;

const Grid = styled.div`
  display: block;
  padding: 20px 50px 20px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
`;

const ButtonToggle = styled.button`
  width: 30px;
  height: 30px;
  top: 20px;
  right: 0;
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: "";
    background: ${({ theme }) => theme.colors.salmon};
    transform: translate(-50%, -50%) rotate(0deg);
    transition: all 300ms;
  }

  &:before {
    width: 20px;
    height: 3px;
  }

  &:after {
    width: 3px;
    height: 20px;
  }

  ${({ active }) =>
    active &&
    `
    &:before, &:after {
        transform: translate(-50%, -50%) rotate(90deg);
        transition: all 300ms;
    }
`}
`;

const QuestionHeader = styled.h3`
  line-height: 30px;
`;

const Answer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  padding-top: 20px;
  padding-bottom: 50px;
  line-height: 1.6;

  a {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`;
