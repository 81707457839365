import { useEffect, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";

import s from "./modal.module.scss";

const TermsOfUseModal = ({ setShowPopup }) => {
  const node = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add(s.hideBodyScroll);

    return () => {
      document.body.classList.remove(s.hideBodyScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  const handleClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      setShowPopup(false);
    }
  };

  return (
    <>
      <div className={s.popupBackground}>
        <div className={s.popupContainer} ref={node}>
          <div className={s.header} data-testid="Popup1Header">
            {t("modal.header")}
          </div>
          <div className={s.textContainer}>
            <b style={{ fontSize: "20px" }}>{t("modal.sub-header")}</b>
            <Trans i18nKey="modal.text1">
              <br />
              Vidua Signing werkt conform een vast ondertekenbeleid waarbij een
              enkele gebruiker een gekwalificeerde elektronische handtekening
              plaatst in het kader van het goedkeuren van de inhoud van het
              ondertekende document.
              <br />
              <br />
              <b> Dwingende bewijskracht</b>
              <br />
              Vidua Signing voorziet alleen in het plaatsen van een
              gekwalificeerde elektronische handtekening nadat de gebruiker is
              geauthentiseerd op niveau eIDAS hoog en door de inzet van een
              gekwalificeerd certificaat, als onderdeel van het profiel van de
              gebruiker. Dit type handtekening is van het hoogste
              betrouwbaarheidsniveau en heeft een dwingende bewijskracht, gelijk
              aan een ‘natte’ handtekening (pen op papier).
              <br />
              <br />
            </Trans>
            <Trans i18nKey="modal.text2">
              <b>Ondertekenen t.b.v. goedkeuren inhoud</b>
              <br /> Vidua Signing kan niet ingezet worden voor andere
              doeleinden dan het goedkeuren van de inhoud van het ondertekende
              document. Ben je hier wel naar op zoek? Neem dan contact op met
              onze klantenservice.
              <br />
              <br />
              <b>Valideren handtekening</b>
              <br /> Een document ondertekend met de Vidua Signing Service kan
              gevalideerd worden door standaard software als Adobe Acrobat
              Reader DC. Er kan hierbij ook gebruik gemaakt worden van een
              online validator aangeboden door de Europese Commissie:{" "}
              <a
                href="https://ec.europa.eu/cefdigital/DSS/webapp-demo/validation"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/cefdigital/DSS/webapp-demo/validation
              </a>
              . Vragen over deze validatie? Neem dan contact op met onze
              klantenservice.
              <br />
            </Trans>
            <Trans i18nKey="modal.text3">
              <br />
              <b>Verifieer verwerking bij ontvangende partij</b>
              <br /> Vidua Signing ondersteunt PDF zonder XML (platte PDF) en
              levert een gekwalificeerde elektronische handtekening van het
              formaat PAdES no XML profile B-B. De gebruiker dient zelf vast te
              stellen of dit formaat wordt geaccepteerd door de ontvangende
              partij. partij.
              <br />
              <br /> <b>Handtekening stempel zonder juridische waarde</b>
              <br />
              Om de gekwalificeerde elektronische handtekening zichtbaar te
              maken wordt er gebruik gemaakt van zichtbare elektronische
              handtekening stempels. Deze stempels worden uitsluitend toegevoegd
              als hulpmiddel om visueel vast te stellen dat er een
              gekwalificeerde elektronische handtekening is gezet.
              <br />
              <br />
              Deze stempel zelf heeft echter geen enkele juridische waarde,
              omdat de stempel eenvoudig vervalst kan worden. De aanwezigheid
              alleen geeft daarom geen garantie dat er daadwerkelijk een geldige
              handtekening is gezet. Je dient daarom altijd een validatie op de
              geldigheid van de handtekening uit tevoeren volgens de aangegeven
              instructies.
              <br />
              <br />
            </Trans>
            <Trans i18nKey="modal.text4">
              <b>Ondertekend document uitprinten</b>
              <br />
              Door het ontbreken van enige juridische waarde van de handtekening
              stempel is het niet mogelijk om de gekwalificeerde elektronische
              handtekening te behouden wanneer het ondertekend document wordt
              uitgeprint. De gekwalificeerde elektronische handtekening kan
              alleen bij digitale verwerking (bijvoorbeeld beveiligd versturen
              via e-mail) behouden blijven.
              <br /> Door in te stemmen met deze gebruiksvoorwaarden, geef je
              aan kennis te hebben genomen van het bovenstaande ondertekenbeleid
              en de wijze waarop handtekening stempels worden ingezet.
              <br />
              <br /> <b style={{ fontSize: "20px" }}>Privacy statement</b>
              <br /> Vidua verwerkt de volgende gegevens tijdens het gebruik van
              Vidua Signing. <br />
              <br />
            </Trans>
            <Trans i18nKey="modal.text5">
              <b>Inloggen</b>
              <br /> Voor inloggen ontvangt en gebruikt Vidua de volgende
              gegevens:
              <ul>
                <li>
                  De gegevens waarvoor je in Vidua Signing toestemming geeft tot
                  delen.
                </li>
                <li>
                  Een door jou ondertekende toestemming om je persoonsgegevens
                  te delen met Vidua Signing.
                </li>
              </ul>
              De gegevens worden gebruikt om je authenticeren op niveau eIDAS
              hoog. Deze gegevens worden gebruikt en vastgehouden tijdens het
              gebruik van Vidua Signing. Wanneer je uitlogt worden de gegevens
              weer verwijderd. <br />
              <br />
              <b>Ondertekenen</b>
              <br /> Voor het ondertekenen ontvangt en gebruikt Vidua de
              volgende gegevens:
              <ul>
                <li>Het aangeboden document</li>
                <li>Ondertekencertificaat</li>
                <li>Publieke sleutel</li>
                <li>Volledige naam eindgebruiker</li>
              </ul>
              De gegevens worden gebruikt om het aangeboden document te voorzien
              van een geldige gekwalificeerde elektronische handtekening. Deze
              gegevens worden gebruikt en vastgehouden tijdens het gebruik van
              Vidua Signing. Wanneer je uitlogt worden de gegevens weer
              verwijderd uit je browser. Het bij Vidua aanwezige document, door
              jou geüploadt, wordt na 24 uur automatisch verwijderd.
              <br />
            </Trans>
            <Trans i18nKey="modal.text6">
              <br /> Vidua Signing maakt gebruikt van de Vidua Hash Signing
              Service en de Vidua Identity Federation Service. Voor beide
              diensten geldt het{" "}
              <a
                href="https://cleverbase.com/privacy-statement/"
                target="_blank"
                rel="noreferrer"
              >
                Vidua Privacy Statement
              </a>
              .
              <br />
              <br /> Door in te stemmen met deze gebruiksvoorwaarden, ga je
              akkoord met het gebruik van de hierboven genoemde
              persoonsgegevens.
            </Trans>
          </div>
          <div className={s.closeBtnRow}>
            <div className={s.closeBtn} onClick={() => setShowPopup(false)}>
              <div className={s.text}>{t("modal.closeBtnRow")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUseModal;
