import styled from 'styled-components'

export const CustomerPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
  font-family: "Nunito Sans",sans-serif;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const OpeningIndicator = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  background: ${props => props.isOpen ? '#cae0da' : '#ffe3e3'};
  svg {
    font-size: 20px;
    color: ${props => props.isOpen ? '#387969' : '#eb3232'};
  }
`

export const OpeningDays = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
`

export const OpeningDay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`

export const DayDescription = styled.span`
  font-weight: 600;
  font-size: 10px;
  color: #98999c;
  margin-left: 4px;
`

export const ClosedDay = styled.div`
  color: #98999C;
`

export const ClosingWindowsPreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background: #FAFAFA;
  border-radius: 24px 0;
`

export const ClosingWindowsHeader = styled.div`
  font-weight: 700;
`
