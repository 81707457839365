import { useTranslation } from "react-i18next";
import SignaturePanel from "./components/signature-panel";
import s from "./validate.module.scss";

const Validate = ({
  view,
  isPanelOpen,
  setIsPanelOpen,
  isLoading,
  response,
  setShowUserErrorWithPDF,
  setShowUserError,
}) => {
  const { t } = useTranslation();

  const statuses = response?.validationsList?.map((i) => i.indication) || [];

  if (response?.validationenabled) {
    if (response?.validationsList?.length === 0) {
      view = "noSign";
      setShowUserErrorWithPDF(false);
      setShowUserError(false);
      sessionStorage.removeItem("signErr");
    } else if (statuses.includes("TOTAL_FAILED")) {
      view = "err";
      setShowUserError(false);
      setShowUserErrorWithPDF(true);
      sessionStorage.setItem("signErr", "true");
    } else if (statuses.includes("INDETERMINATE")) {
      view = "warning";
      setShowUserError(false);
      setShowUserErrorWithPDF(false);
      sessionStorage.removeItem("signErr");
    } else if (
      statuses.includes("TOTAL_PASSED") &&
      response?.validationsList?.length > 0
    ) {
      view = "sign";
      setShowUserError(false);
      setShowUserErrorWithPDF(false);
      sessionStorage.removeItem("signErr");
    }
  } else {
    view = "noValidationEnabled";
    setShowUserError(false);
    setShowUserErrorWithPDF(false);
    sessionStorage.removeItem("signErr");
  }

  if (isLoading) {
    view = "default";
    setShowUserError(false);
    setShowUserErrorWithPDF(false);
  }

  return (
    <>
      {isPanelOpen && (
        <SignaturePanel setIsPanelOpen={setIsPanelOpen} response={response} />
      )}
      {view === "default" && (
        <div className={s.defaultContainer}>
          <div>{t("validate.default")}</div>
          <div className={s.loader}></div>
        </div>
      )}
      {view === "noSign" && (
        <div className={s.noSignContainer}>{t("validate.noSign")}</div>
      )}
      {view === "sign" && (
        <div className={s.signContainer} onClick={() => setIsPanelOpen(true)}>
          <div>
            {response?.validationsList?.length} {t("validate.sign")}
          </div>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99438 1.74707C5.13038 1.74707 1.99438 4.88307 1.99438 8.74707C1.99438 12.6111 5.13038 15.7471 8.99438 15.7471C12.8584 15.7471 15.9944 12.6111 15.9944 8.74707C15.9944 4.88307 12.8584 1.74707 8.99438 1.74707ZM9.69438 12.2471H8.29438V8.04707H9.69438V12.2471ZM9.69438 6.64707H8.29438V5.24707H9.69438V6.64707Z"
              fill="#6FAC9D"
            />
          </svg>
        </div>
      )}
      {view === "err" && (
        <div className={s.errContainer} onClick={() => setIsPanelOpen(true)}>
          <div>{t("validate.err")}</div>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99438 1.74707C5.13038 1.74707 1.99438 4.88307 1.99438 8.74707C1.99438 12.6111 5.13038 15.7471 8.99438 15.7471C12.8584 15.7471 15.9944 12.6111 15.9944 8.74707C15.9944 4.88307 12.8584 1.74707 8.99438 1.74707ZM9.69438 12.2471H8.29438V8.04707H9.69438V12.2471ZM9.69438 6.64707H8.29438V5.24707H9.69438V6.64707Z"
              fill="#6FAC9D"
            />
          </svg>
        </div>
      )}
      {view === "warning" && (
        <div
          className={s.warinngContainer}
          onClick={() => setIsPanelOpen(true)}
        >
          <div>{t("validate.warning")}</div>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99438 1.74707C5.13038 1.74707 1.99438 4.88307 1.99438 8.74707C1.99438 12.6111 5.13038 15.7471 8.99438 15.7471C12.8584 15.7471 15.9944 12.6111 15.9944 8.74707C15.9944 4.88307 12.8584 1.74707 8.99438 1.74707ZM9.69438 12.2471H8.29438V8.04707H9.69438V12.2471ZM9.69438 6.64707H8.29438V5.24707H9.69438V6.64707Z"
              fill="#6FAC9D"
            />
          </svg>
        </div>
      )}
      {view === "noValidationEnabled" && (
        <div className={s.disabledContainer}></div>
      )}
    </>
  );
};

export default Validate;
